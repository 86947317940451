import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-template-single-select-option',
  templateUrl: './template-single-select-option.component.html',
  styleUrls: ['./template-single-select-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateSingleSelectOptionComponent {
  @Input() option: unknown;

  @HostBinding('class.active') isActive = false;
  @HostBinding('class.selected') isSelected = false;

  optionSelected$ = new Subject<any>();

  @HostListener('click')
  onClick() {
    this.optionSelected$.next(this.option);
  }
}
