import { UserSelfFragment } from '../../../graphql/generated';

export function userHasMatchingEnabled(user: UserSelfFragment) {
  return (
    user.permissions?.fullAccess ||
    user.organisationUnit?.branches?.some(
      (b) => b.customerBranch.matchingEnabled,
    )
  );
}
