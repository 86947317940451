import { createReducer, on } from '@ngrx/store';
import {
  VacanciesApiActions,
  VacanciesListActions,
  vacancyListTabableActions,
} from './vacancies-list.actions';
import { VacanciesListState } from './vacancies-list-state.types';
import {
  chooseDefaultActiveVacancyTab,
  tableConfigurationColumnsOfVacancyFilterLens,
} from './vacancies-list.optics';
import { CurrentUserApiActions } from '../current-user/current-user.actions';
import { Option, pipe } from 'effect';
import { initialVacancyTableConfiguration } from '../../shared/modules/vacancies-table/vacancies-table.settings';
import { Injectable } from '@angular/core';
import { UserService } from '../../models/shared/user/user.service';
import { lastValueFrom } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { filterIsNotEmpty } from '../../shared/helpers/functions/filterIsNotEmpty';
import * as Optic from '@fp-ts/optic';
import { setDefaultElementsInPosition } from '../../shared/helpers/functions/setDefaultElementsInPosition';
import { ActivatedRoute } from '@angular/router';
import {
  clearFilterHistory,
  replaceSortOfCurrentUser,
  tabsLens,
} from '../advanced-data-list/advanced-data-list.optics';
import { tabReducers } from '../tab-management/reducer';

export const PAGE_SIZE = 1000;

export const regularReducer = (initialState: VacanciesListState) => {
  return createReducer(
    initialState,
    on(
      VacanciesApiActions.loadingVacancyIdsSucceeded,
      (_state, { vacancyIds, totalCount }): VacanciesListState => {
        return {
          ..._state,
          itemIds: vacancyIds,
          isLoadingItems: false,
          totalItemsCount: Option.some(totalCount),
        };
      },
    ),
    on(
      VacanciesApiActions.loadingVacanciesStarted,
      (_state): VacanciesListState => ({
        ..._state,
        isLoadingItems: true,
      }),
    ),
    on(
      VacanciesApiActions.loadingVacanciesErrored,
      (_state): VacanciesListState => ({
        ..._state,
        isLoadingItems: false,
      }),
    ),
    on(
      VacanciesListActions.temporaryQueryChanged,
      (_state, { searchChips }): VacanciesListState => ({
        ..._state,
        temporaryQueryChips: searchChips,
        displayItemsLimit: PAGE_SIZE,
      }),
    ),
    on(
      VacanciesListActions.loadMoreVacanciesClicked,
      (_state): VacanciesListState => ({
        ..._state,
        isLoadingItems: true,
        displayItemsLimit: _state.displayItemsLimit + 1000,
      }),
    ),
    ...tabReducers<VacanciesListState>(vacancyListTabableActions),
    on(
      VacanciesApiActions.loadingPinnedTabCountSucceeded,
      (_state, { tabId, count }): VacanciesListState => ({
        ..._state,
        ...{
          tabTotalCounts: {
            ..._state.tabTotalCounts,
            [tabId]: count,
          },
        },
      }),
    ),
    on(
      VacanciesApiActions.loadingSearchOptionsSucceeded,
      (_state, { searchOptions }): VacanciesListState => ({
        ..._state,
        ...{ searchOptions },
      }),
    ),
    on(VacanciesApiActions.vacancySearchHistoryCleared, clearFilterHistory()),
    on(
      CurrentUserApiActions.userLoadedSuccess,
      (_state, userLoadedProps): VacanciesListState => {
        return {
          ..._state,
          filterHistory: userLoadedProps.vacancyFilterHistory,
        };
      },
    ),
    on(
      VacanciesApiActions.vacancySearchHistoryAddingSucceeded,
      (_state, { updatedFilterHistory }): VacanciesListState => ({
        ..._state,
        filterHistory: updatedFilterHistory,
      }),
    ),
    on(
      VacanciesApiActions.receivedVacancyListItemData,
      (_state, listItem): VacanciesListState => ({
        ..._state,
        listItemsById: {
          ..._state.listItemsById,
          [listItem.uuid]: listItem,
        },
      }),
    ),
    on(
      VacanciesListActions.searchHistoryOpened,
      (_state): VacanciesListState => ({
        ..._state,
        showFilterHistory: true,
      }),
    ),
    on(
      VacanciesListActions.searchHistoryClosed,
      (_state): VacanciesListState => ({
        ..._state,
        showFilterHistory: false,
      }),
    ),
    on(
      vacancyListTabableActions.sortChanged,
      (_state, { sort, tabId }): VacanciesListState => ({
        ...replaceSortOfCurrentUser<VacanciesListState>(_state, tabId, sort),
        displayItemsLimit: PAGE_SIZE,
      }),
    ),
    on(CurrentUserApiActions.userLoadedSuccess, (_state, userLoadedProps) =>
      Optic.modify(tabsLens<VacanciesListState>())((filters) =>
        filters.map(
          Optic.modify(tableConfigurationColumnsOfVacancyFilterLens())(
            (columns) =>
              setDefaultElementsInPosition(
                initialVacancyTableConfiguration,
                columns,
                (column1, column2) => column1.columnId === column2.columnId,
              ),
          ),
        ),
      )(_state),
    ),
  );
};

@Injectable({
  providedIn: 'root',
})
export class InitialVacanciesListStateLoaderService {
  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {}

  private initialState: VacanciesListState | undefined;

  bootstrap(): Promise<boolean> {
    const params = new URLSearchParams(window.location.search);
    const activeTab = params.has('tabId')
      ? Option.some(params.get('tabId')!)
      : Option.none();

    return this.initialState !== undefined
      ? Promise.resolve(true)
      : lastValueFrom(
          this.userService.readyUser$.pipe(
            filter(filterIsNotEmpty),
            map((user): VacanciesListState => {
              const userVacancyFilters: NonNullable<
                (typeof user)['vacancyFilters']
              > = user.vacancyFilters ?? [];
              return {
                isLoadingItems: true,
                itemIds: [],
                listItemsById: {},
                displayItemsLimit: PAGE_SIZE,
                totalItemsCount: Option.none(),
                tabs: userVacancyFilters.map((tab) =>
                  pipe(
                    tab,
                    // this ensures that the criteria are always complete
                    (tab) => ({
                      ...tab,
                      criteria: [...tab.criteria],
                    }),
                    Optic.modify(
                      tableConfigurationColumnsOfVacancyFilterLens(),
                    )((columns) =>
                      setDefaultElementsInPosition(
                        initialVacancyTableConfiguration,
                        columns,
                        (column1, column2) =>
                          column1.columnId === column2.columnId,
                      ),
                    ),
                  ),
                ),
                editActiveTabId: false,
                tabTotalCounts: {},
                consultantUsers: [],
                customerBranches: [],
                searchOptions: [],
                temporaryQueryChips: [],
                filterHistory: user.vacancyFilterHistory ?? [],
                showFilterHistory: false,
                tabToDeleteId: Option.none(),
                activeTabId: pipe(
                  activeTab,
                  Option.flatMap(
                    Option.liftPredicate((tabId) =>
                      userVacancyFilters.some(
                        (tab) => tab.pinned && tab.uuid === tabId,
                      ),
                    ),
                  ),
                  Option.getOrElse(
                    () =>
                      chooseDefaultActiveVacancyTab(userVacancyFilters).uuid,
                  ),
                ),
              };
            }),
            take(1),
            tap((state) => (this.initialState = state)),
            map((_) => true),
          ),
        );
  }

  createReducer() {
    // At this point initialState should not be undefined anymore
    return regularReducer(this.initialState as VacanciesListState);
  }
}
