<div
  class="uj-h-g uj-overflow-visible uj-relative"
  [ngClass]="{
    'uj-z-10': showOptions,
    'uj-cursor-not-allowed': isDisabled,
  }"
  (appClickedOutside)="close()">
  <div
    #dropdownFocusElement
    tabindex="0"
    class="uj-border uj-border-border hover:elevation-1 uj-rounded focus:uj-border-border-focus"
    [class.uj-bg-surface-grape]="isDisabled"
    [ngClass]="{
      'elevation-1': showOptions,
      'uj-pointer-events-none': isDisabled,
      'uj-bg-surface-main': !isDisabled,
      'uj-bg-surface-grape': isDisabled,
    }">
    <div>
      <div
        (click)="open()"
        class="uj-px-d uj-h-g uj-relative uj-flex uj-items-center uj-cursor-pointer">
        <div
          class="uj-grow uj-flex uj-items-center uj-gap-d uj-mr-0 lg:uj-mr-d">
          <ng-container
            *ngIf="!showOptions && selectedOption; else placeholder">
            <ng-container
              *ngTemplateOutlet="
                selectedOptionTemplate;
                context: { option: selectedOption }
              "></ng-container>
          </ng-container>
          <ng-template #placeholder>
            <ng-content select="[slot=placeholder]"></ng-content>
          </ng-template>
        </div>
        <!-- desktop only -->
        <div class="uj-h-e uj-w-[1px] uj-bg-border uj-hidden lg:uj-block"></div>
        <div class="uj-hidden lg:uj-block">
          <button
            *ngIf="!this.loading"
            [disabled]="isDisabled"
            ui-library-button-text
            color="dark"
            [iconOnly]="showOptions ? 'chevron_up' : 'chevron_down'"
            class="uj-transition-transform"
            (click)="$event.stopPropagation(); toggleOptions()"></button>
        </div>
        <button
          *ngIf="this.loading"
          [disabled]="isDisabled"
          ui-library-button-text
          color="dark"
          iconOnly="chances_progress"></button>
      </div>
    </div>
    <div
      *ngIf="showOptions"
      class="uj-overflow-y-auto uj-max-h-[40vh]">
      <div class="uj-mx-d uj-border-t uj-border-border"></div>
      <div
        class="uj-flex uj-flex-col"
        [@filterAnimation]="options.length">
        <ng-content select="[slot=options]"></ng-content>
      </div>
    </div>
  </div>
</div>
