<!-- It would not be necessary to use the ui-library-list-item here. It is only used to use the styling is correct.
TODO: Use the classes from figma-preset instead once it is available as a package.
 -->
<ui-library-list-item
  [_value]="value()"
  [label]="label">
  <div class="uj-grow uj-flex uj-items-center uj-gap-d uj-mr-0 lg:uj-mr-d">
    <app-user-avatar
      [user]="consultant"
      class="uj-mr-d"
      size="s" />
    <div class="uj-flex uj-flex-col uj-grow uj-w-0">
      <div class="text-m-b uj-truncate">
        <span
          [innerHTML]="
            consultant.profile.firstName + ' ' + consultant.profile.lastName
              | highlightSearch: decorationNonNull
          "></span>
        <ng-container *ngIf="(loggedInUser$ | async)?._id === consultant._id">
          ({{ "iMyself" | i18next }})
        </ng-container>
      </div>
      <div
        class="text-m uj-truncate"
        [innerHTML]="
          consultant.profile.position ?? '' | highlightSearch: decorationNonNull
        "></div>
    </div>
  </div>
</ui-library-list-item>
