import { ModularSearchChip } from '../../shared/modules/modular-searchbar/types/modular-search-options';
import { FilterCriteriaConditionEnum } from '../../graphql/generated';
import { Tababble } from '../tab-management/state.types';

function convertChipToCriteriaCondition(chip: ModularSearchChip<string>) {
  const isTextSearch = !chip.searchOption.subOptions?.length;
  if (isTextSearch) {
    return chip.equals
      ? FilterCriteriaConditionEnum.CONTAINS
      : FilterCriteriaConditionEnum.NCONTAINS;
  }
  return chip.equals
    ? FilterCriteriaConditionEnum.EQ
    : FilterCriteriaConditionEnum.NEQ;
}

export function convertChipsToCriteria<S extends Tababble>(
  chips: readonly ModularSearchChip<
    S['tabs'][number]['criteria'][number]['field']
  >[],
): S['tabs'][number]['criteria'] {
  return chips.map((chip) => {
    return {
      field: chip.searchOption.key,
      values: (chip.values || []).map((v) => v.value),
      condition: convertChipToCriteriaCondition(chip),
    };
  }) as S['tabs'][number]['criteria'];
}
