import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import UserbackInit, { UserbackWidget } from '@userback/widget';
import { isPlatformBrowser } from '@angular/common';
import { BuildInfoService } from './build-infos.service';
import { UserService } from '../../models/shared/user/user.service';
import { UserbackWidgetSettings } from '@userback/widget/widget';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserbackService {
  isBrowser = false;
  widget?: Promise<UserbackWidget | undefined>;
  localData: { [key: string]: any } = {};
  token = '35781|83168|52R3Qd2iY2CXVelwar8C1whru';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    buildInfoService: BuildInfoService,
    userService: UserService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (!this.userBackEnabled()) {
      return;
    }
    this.initWidget();
    buildInfoService.getBuildInfo().subscribe((buildInfo) => {
      this.addDataItem('env', buildInfo.env);
      this.addDataItem('tag', buildInfo.tag);
      this.addDataItem('commit', buildInfo.commit);
      this.addDataItem('branch', buildInfo.branch);
    });
    userService.user$.subscribe((user) => {
      if (user && user.profile.email) {
        this.updateIdentification(user._id, {
          ...user.profile,
          email: user.profile.email,
          name: user.profile.firstName + ' ' + user.profile.lastName,
        });
      }
    });
  }

  userBackEnabled(): boolean {
    return (
      this.isBrowser &&
      (environment.environment === 'DEMO' || environment.environment === 'PROD')
    );
  }

  addDataItem(key: string, value: any): void {
    this.localData[key] = value;
    this.updateWidgetData();
  }

  removeDataItem(key: string): void {
    delete this.localData[key];
    this.updateWidgetData();
  }

  private async updateWidgetData(): Promise<void> {
    const widgetInstance = await this.widget;
    if (!widgetInstance) {
      return;
    }
    widgetInstance?.setData(this.localData);
  }

  private async updateIdentification(
    user_id: string,
    user_info: {
      name?: string;
      email?: string;
    },
  ) {
    const widgetInstance = await this.widget;
    if (!widgetInstance) {
      return;
    }
    widgetInstance?.identify(user_id, user_info);
  }

  async updateWidgetLanguage(language: string): Promise<void> {
    if (!this.userBackEnabled()) {
      return;
    }
    const widgetInstance = await this.widget;
    if (widgetInstance) {
      widgetInstance.destroy();
    }
    // Destroy and recreate widget as documented by userback:
    // https://docs.userback.io/docs/dynamic-language#dynamic-language-switching
    this.initWidget(language as UserbackWidgetSettings['language']);
    this.addDataItem('language', language.toLowerCase());
  }

  initWidget(language?: UserbackWidgetSettings['language']): void {
    if (!this.userBackEnabled()) {
      return;
    }
    this.widget = UserbackInit(this.token, {
      widget_settings: {
        language,
      },
    });
  }
}
