export class PromiseQueue {
  private queue: Array<() => Promise<any>> = [];
  processing = false;

  async addToQueue<T>(request: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.queue.push(async () => {
        try {
          const result = await request();
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
      this.processQueue();
    });
  }

  private async processQueue(): Promise<void> {
    if (this.processing) {
      return;
    }
    this.processing = true;

    while (this.queue.length > 0) {
      const currentRequest = this.queue.shift();
      if (currentRequest) {
        try {
          await currentRequest();
        } catch (error) {
          // Handle error if necessary
          console.error('Error processing request:', error);
        }
      }
    }

    this.processing = false;
  }
}
