import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TemplateSingleSelectOptionComponent } from './template-single-select-option/template-single-select-option.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BehaviorSubject, merge, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template-single-select',
  templateUrl: './template-single-select.component.html',
  styleUrls: ['./template-single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemplateSingleSelectComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('filterAnimation', [
      transition(':enter, * => 0, * => -1', []),
      transition(':increment', [
        query(
          ':enter',
          [
            style({ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }),
            stagger(15, [
              animate(
                '100ms ease-out',
                style({
                  opacity: 1,
                  height: '*',
                  paddingTop: '*',
                  paddingBottom: '*',
                }),
              ),
            ]),
          ],
          { optional: true },
        ),
      ]),
      transition(':decrement', [
        query(':leave', [
          stagger(15, [
            animate(
              '100ms ease-out',
              style({ opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 }),
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class TemplateSingleSelectComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  destroyed$ = new Subject<void>();
  onChange = (_: any) => {};
  onTouched = () => {};
  isDisabled = false;

  @Input({ required: true }) selectedOptionTemplate!: TemplateRef<any>;
  @Input() loading = false;
  @Output() toggledOpen = new EventEmitter<void>();
  @Output() toggledClose = new EventEmitter<void>();

  @ViewChild('dropdownFocusElement') dropdownFocusElement?: ElementRef;

  @ContentChildren(TemplateSingleSelectOptionComponent)
  optionComponents?: QueryList<TemplateSingleSelectOptionComponent>;
  optionComponents$ = new BehaviorSubject<
    TemplateSingleSelectOptionComponent[]
  >([]);

  options: unknown[] = [];

  selectedOption: unknown;
  activeOptionIndex = -1;
  showOptions = false;

  childOptionSelected$ = this.optionComponents$.pipe(
    switchMap((optionComponents) => {
      return merge(
        ...optionComponents.map((oc) => {
          return oc.optionSelected$;
        }),
      );
    }),
  );
  // Allows element to have the focus state
  @HostBinding('attr.tabindex') tabindex = -1;

  @HostListener('keydown.arrowDown')
  handleArrowDown(): void {
    if (!this.showOptions) {
      this.open();
    }
    if (!this.options) {
      return;
    }
    if (this.activeOptionIndex < this.options?.length - 1) {
      this.updateActiveOptionIndex(this.activeOptionIndex + 1);
    }
  }

  @HostListener('keydown.arrowUp')
  handleArrowUp(): void {
    if (!this.showOptions) {
      this.open();
    }
    if (!this.options) {
      return;
    }
    if (this.activeOptionIndex > -1) {
      this.updateActiveOptionIndex(this.activeOptionIndex - 1);
    }
  }

  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  onKeyboardSubmit(event: KeyboardEvent) {
    if (this.activeOptionIndex > -1) {
      this.submitOption(this.options[this.activeOptionIndex]);
    } else {
      if (this.showOptions) {
        this.close();
      } else {
        this.open();
      }
    }
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('keydown.escape', ['$event'])
  onEscape = (event: Event) => {
    event.stopPropagation();
    this.close();
  };

  @HostListener('keydown.tab')
  onTab = () => this.close();

  constructor() {
    this.optionComponents$.pipe(takeUntil(this.destroyed$)).subscribe((c) => {
      this.updateOptions(c);
    });
    this.childOptionSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((option) => {
        this.submitOption(option);
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  writeValue(option: unknown): void {
    this.updateSelectedOption(option);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngAfterViewInit() {
    if (!this.optionComponents) {
      console.error('QueryList QueryList not ready in ngAfterViewInit');
      return;
    }
    this.optionComponents$.next(this.optionComponents.toArray());
    this.optionComponents.changes.subscribe(() => {
      this.optionComponents$.next(this.optionComponents?.toArray() || []);
    });
  }

  public open(): void {
    this.showOptions = true;
    // timeout makes sure that the placeholder (where the searchbar might be) is rendered on emit
    setTimeout(() => {
      this.toggledOpen.emit();
    });
  }

  public close(): void {
    this.showOptions = false;
    this.updateActiveOptionIndex(-1);
    this.toggledClose.emit();
  }

  submitOption(option: unknown): void {
    if (this.isDisabled) return;
    if (this.selectedOption === option) return;
    this.updateSelectedOption(option);
    this.onChange(option);
    this.close();
    this.dropdownFocusElement?.nativeElement.focus();
  }

  updateOptions(options: TemplateSingleSelectOptionComponent[]) {
    this.options = options.map((o) => o.option);
    this.updateActiveOptionIndex(-1);
  }

  updateActiveOptionIndex(activeOptionIndex: number) {
    this.activeOptionIndex = activeOptionIndex;
    this.optionComponents?.forEach((o, i) => {
      o.isActive = i === this.activeOptionIndex;
    });
  }

  updateSelectedOption(option: unknown) {
    this.selectedOption = option;
    this.optionComponents?.forEach((o) => {
      o.isSelected = o.option === option;
    });
  }

  toggleOptions(): void {
    if (this.isDisabled) return;
    setTimeout(() => {
      const newOpen = !this.showOptions;
      if (newOpen) {
        this.open();
      } else {
        this.close();
      }
    });
  }
}
