import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import {
  DecoratableComponent,
  IDecoratable,
  ISelectable,
  ListItemComponent,
  SelectableComponent,
} from '@intemp/unijob-ui2';
import { AsyncPipe, NgIf } from '@angular/common';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { UserAvatarModule } from '../../user-avatar/user-avatar.module';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  Subject,
} from 'rxjs';
import { UserListItemFragment } from '../../../../graphql/generated';
import { filter } from 'rxjs/operators';
import { filterIsNotEmpty } from '../../../helpers/functions/filterIsNotEmpty';
import { UserService } from '../../../../models/shared/user/user.service';
import { Constructor } from 'type-fest';

export const SelectableProvider = <
  TKey,
  TValue,
  TSelectable extends ISelectable<TKey, TValue>,
  TConstructorArgs extends unknown[],
>(
  component: Constructor<TSelectable, TConstructorArgs>,
) => {
  return {
    provide: SelectableComponent<TKey, TValue>,
    useExisting: forwardRef(() => component),
  };
};

export const DecoratableProvider = <
  TDecoration,
  TSelectable extends IDecoratable<TDecoration>,
  TConstructorArgs extends unknown[],
>(
  component: Constructor<TSelectable, TConstructorArgs>,
) => {
  return {
    provide: DecoratableComponent<TDecoration>,
    useExisting: forwardRef(() => component),
  };
};

@Component({
  selector: 'app-consultant-select-list-item',
  standalone: true,
  imports: [
    ListItemComponent,
    AsyncPipe,
    NgIf,
    SharedDefaultModule,
    UserAvatarModule,
  ],
  templateUrl: './consultant-select-list-item.component.html',
  styleUrl: './consultant-select-list-item.component.scss',
  providers: [
    SelectableProvider(ConsultantSelectListItemComponent),
    DecoratableProvider(ConsultantSelectListItemComponent),
  ],
})
export class ConsultantSelectListItemComponent
  implements
    AfterViewInit,
    ISelectable<string, UserListItemFragment>,
    IDecoratable<string>
{
  @Input({ required: true })
  _value!: string;
  @Input({ required: true })
  label!: UserListItemFragment;

  @ViewChild(ListItemComponent<string, UserListItemFragment>, { static: true })
  listItem!: ListItemComponent<string, UserListItemFragment>;

  _selected = new BehaviorSubject(false);
  isSelected: Observable<boolean> = this._selected.pipe(distinctUntilChanged());

  isVisible = signal(false);

  // To loop ng model through - TODO: Keep the last write value within the select to avoid this
  onChange = new Subject<void>();

  loggedInUser$ = this.userService.user$.pipe(filter(filterIsNotEmpty));

  decoration?: string;

  set isEnabled(isEnabled: boolean) {
    this.listItem.setEnabled(isEnabled);
  }

  get isEnabled() {
    return this.listItem.isEnabled;
  }

  get decorationNonNull() {
    return this.decoration ?? '';
  }

  get consultant(): UserListItemFragment {
    return this.label;
  }

  constructor(
    private _elementRef: ElementRef,
    private userService: UserService,
  ) {}

  elementRef(): ElementRef<any> {
    return this._elementRef;
  }

  ngAfterViewInit(): void {
    this.listItem._selected
      .pipe(distinctUntilChanged())
      .subscribe((value: boolean) => this._selected.next(value));
    this.listItem.onChange.subscribe(this.onChange);
    // In later versions of angular (17.2+) list item can be a signal too
    this.isVisible = this.listItem.isVisible;
  }

  select(): void {
    this.listItem.select();
  }
  deselect(): void {
    this.listItem.deselect();
  }
  toggle(): void {
    this.listItem.toggle();
  }
  hide(): void {
    this.listItem.hide();
  }
  show(): void {
    this.listItem.show();
  }
  value(): string {
    return this._value;
  }
  content(): UserListItemFragment {
    return this.label;
  }

  disable() {
    this.isEnabled = false;
  }

  enable() {
    this.isEnabled = true;
  }

  toggleEnabled() {
    this.isEnabled = !this.isEnabled;
  }
}
