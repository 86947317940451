<div class="uj-grow uj-flex uj-items-center uj-gap-d uj-mr-0 lg:uj-mr-d">
  <app-user-avatar
    [user]="consultant"
    class="uj-mr-d"
    size="s"></app-user-avatar>
  <div class="uj-flex uj-flex-col uj-grow uj-w-0">
    <div class="text-m-b uj-truncate">
      {{ consultant.profile.firstName }} {{ consultant.profile.lastName }}
      <ng-container *ngIf="(loggedInUser$ | async)?._id === consultant._id">
        ({{ "iMyself" | i18next }})
      </ng-container>
    </div>
    <div class="text-m uj-truncate">
      {{ consultant.profile.position }}
    </div>
  </div>

  <!-- TODO: Use the avatar selected item instead -->
  <!-- Desktop acitons -->
  <div class="uj-hidden lg:uj-flex">
    <a
      *ngIf="consultant.profile.email"
      ui-library-button-text
      type="button"
      target="_blank"
      (click)="$event.stopPropagation()"
      [href]="consultant.profile.email | teamsLink"
      [tooltip]="'microsoftTeams' | i18next"
      class="uj-pointer-events-auto"
      color="dark"
      iconOnly="teams"></a>
    <a
      *ngIf="consultant.profile.phone"
      ui-library-button-text
      type="button"
      target="_blank"
      (click)="$event.stopPropagation()"
      [href]="'tel:' + consultant.profile.phone"
      [tooltip]="'call' | i18next"
      class="uj-pointer-events-auto"
      color="dark"
      iconOnly="phone"></a>
  </div>
  <div
    *ngIf="consultant.profile.phone || consultant.profile.email"
    class="uj-h-e uj-w-[1px] uj-bg-border uj-hidden lg:uj-block"></div>

  <!-- mobile actions -->
  <div class="uj-block lg:uj-hidden">
    <button
      ui-library-button-text
      *ngIf="moreActionsContextMenu"
      type="button"
      iconOnly="menu_dots_vertical"
      (click)="$event.stopPropagation()"
      [contextMenu]="moreActionsContextMenu"></button>

    <ng-template #moreActionsContextMenu>
      <a
        *ngIf="consultant.profile.email"
        ui-library-button-text
        target="_blank"
        type="button"
        color="dark"
        iconLeft="teams"
        (click)="$event.stopPropagation()"
        [href]="consultant.profile.email | teamsLink">
        {{ "contactOnTeams" | i18next }}
      </a>
      <a
        *ngIf="consultant.profile.phone"
        ui-library-button-text
        target="_blank"
        type="button"
        color="dark"
        iconLeft="phone"
        (click)="$event.stopPropagation()"
        [href]="'tel:' + consultant.profile.phone">
        {{ "call" | i18next }}
      </a>
      <div class="uj-mx-d uj-border-t uj-border-border"></div>
      <button
        ui-library-button-text
        type="button"
        color="dark"
        iconLeft="flexibility_arrows">
        <!--  TODO: Provide toggle option      -->
        <!--        (click)="toggleOptions()"-->
        {{ "changeSelection" | i18next }}
      </button>
    </ng-template>
  </div>
</div>
