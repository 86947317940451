<span
  *ngIf="variant() === 'dot-text'"
  class="uj-flex uj-text-text"
  [class.text-s-b]="textSize() === 'small'"
  [class.text-m-b]="textSize() === 'medium'"
  [class.uj-gap-c]="!extendedFormat()"
  [class.uj-flex-col]="extendedFormat()">
  <div class="uj-flex uj-items-center uj-gap-a">
    <span
      [class.uj-mr-b]="variant() === 'dot-text'"
      ui-library-dot
      size="S"
      corner="round"
      [inverted]="computedValues()?.inverted"
      [color]="computedValues()?.dotColor">
    </span>
    {{ "vacancyStatusEnum." + vacancy()?.status | i18next }}
    <ng-container *ngIf="showDate()">
      <span
        class="uj-hidden sm:uj-inline"
        [class.text-s]="textSize() === 'small'"
        [class.text-m]="textSize() === 'medium'">
        {{
          computedValues()?.dateTranslationKey || ""
            | i18next
              : {
                  date: computedValues()?.date | date,
                  duration: computedValues()?.duration,
                  durationUnit: computedValues()?.durationUnit
                    ? (computedValues()?.durationUnit ?? "" | i18next)
                    : {},
                }
        }}
      </span>
      <span
        class="sm:uj-hidden"
        [class.text-s]="textSize() === 'small'"
        [class.text-m]="textSize() === 'medium'">
        {{ computedValues()?.date | date }}
      </span>
    </ng-container>
  </div>
  <div
    class="uj-flex uj-items-center uj-gap-c"
    [class.text-s]="textSize() === 'small'"
    [class.text-m]="textSize() === 'medium'"
    *ngIf="hasChanges()">
    <span>+</span>
    <span class="unijob-dot color-pink uj-flex uj-items-center"></span>
    <span class="uj-hidden sm:uj-inline">
      <div
        *ngIf="extendedFormat(); else shortFormat"
        class="text-m-b">
        {{ "unpublishedChanges" | i18next }}
      </div>
      <ng-template #shortFormat>
        {{ "changes" | i18next }}
      </ng-template>
    </span>
  </div>
</span>
<ng-container *ngIf="variant() === 'avatar'">
  <ui-library-avatar
    [title]="'vacancyStatusEnum.' + vacancy()?.status | i18next"
    class="uj-display-inline-block"
    [class.uj-border-brand-accent]="hasChanges()"
    [borderColor]="hasChanges() ? 'pink' : 'grey'"
    content="icon"
    icon="suitcase"
    size="xs"
    shape="round"
    tagType="tagAvatar">
    <ng-container slot="tag">
      <span
        ui-library-dot
        size="S"
        corner="round"
        [inverted]="computedValues()?.inverted"
        [color]="computedValues()?.dotColor">
      </span>
    </ng-container>
  </ui-library-avatar>
</ng-container>
