import * as Optic from '@fp-ts/optic';
import { Lens, Optional } from '@fp-ts/optic';
import {
  VacanciesListState,
  VacanciesListStateColumnConfiguration,
} from '../vacancies-list/vacancies-list-state.types';
import {
  TalentsListState,
  TalentsListStateColumnConfiguration,
} from '../talents-list/talents-list-state.types';
import { Option, ReadonlyArray } from 'effect';
import {
  MatchFilterFragment,
  TalentFilterFragment,
  VacancyFilterFragment,
} from '../../graphql/generated';
import { firstElementBy } from '../../shared/helpers/functions/optics/filterFirstElement.optics';
import { Tababble } from '../tab-management/state.types';
import {
  MatchesListState,
  MatchesListStateColumnConfiguration,
} from '../matches-list/matches-list-state.types';

/*export const clearFilterHistory = <
  T extends AdvancedDataListState<any, any, any, any>,
>(
  state: T,
): T => ({
  ...state,
  filterHistory: [],
});*/

type States = VacanciesListState | TalentsListState | MatchesListState;

/**
 * An optic that zooms in to the filters tabs.
 */
export const tabsLens: <T extends Tababble>() => Lens<T, T['tabs']> = <
  T extends Tababble,
>() => Optic.id<T>().at('tabs');

/**
 * This is an alternative array filter function that returns the correct
 * array type. Otherwise, it would not work for an array of unions.
 * Example of what is not working:
 *
 *       const ab: string[] | number[] = ([] as string[] | number[]).filter(
 *          (value) => false,
 *       );
 * Problem: you pass in string[] | number[] and get (string |number[]) which
 * could be considered a bug and we work around it with this function
 * @param array
 * @param predicate
 */
export const filter = <array extends readonly unknown[]>(
  array: array,
  predicate: Parameters<array['filter']>[0],
): array => array.filter(predicate) as never;

/**
 * This is an alternative find function that returns the correct type.
 *
 * See filter function above.
 *
 * @param array
 * @param predicate
 */
export const find = <array extends readonly T[], T>(
  array: array,
  predicate: Parameters<array['filter']>[0],
): T => array.find(predicate) as never;

/**
 * Removes the filter with the given uuid and returns
 * the complete list state.
 * @param uuid    filter uuid
 */
export function removePermanentFilter<T extends Tababble>(
  uuid: string,
): (state: T) => T {
  return Optic.modify(tabsLens<T>())((filters) =>
    filter(filters, (filter: T['tabs'][number]) => filter.uuid !== uuid),
  );
}

/**
 * An optic that zooms in to the table configuration of a filter.
 */
export const tableConfigurationColumnsOfFilterLens: <
  T extends States,
>() => Optional<
  T['tabs'][0],
  ReadonlyArray<T['tabs'][0]['tableConfiguration']['columns'][number]>
> = <T extends States>() =>
  Optic.id<T['tabs'][0]>()
    .at('tableConfiguration')
    .at('columns') satisfies Optional<
    T['tabs'][0],
    // TODO: Write a type that converts from (A|B)[] to A[]|B[],
    //  so we do not have to mention the types manually here
    | ReadonlyArray<TalentsListStateColumnConfiguration>
    | ReadonlyArray<VacanciesListStateColumnConfiguration>
    | ReadonlyArray<MatchesListStateColumnConfiguration>
  > as Optional<
    T['tabs'][0],
    ReadonlyArray<T['tabs'][0]['tableConfiguration']['columns'][number]>
  >;

/**
 * This function returns an optic that zooms into a filter with a given uuid
 * within an array of filters.
 * @param uuid  filter uuid
 */
export function filterForUuidOptic<T extends Tababble>(
  uuid: string,
): Optional<ReadonlyArray<T['tabs'][number]>, T['tabs'][number]> {
  return Optic.id<ReadonlyArray<T['tabs'][number]>>().compose(
    firstElementBy<T['tabs'][number]>((filter) => filter.uuid === uuid),
  );
}

/**
 * This function returns an optic that zooms into a filter with a given uuid
 * @param uuid  filter uuid
 */
export function filterWithUuidOfCurrentUser<T extends Tababble>(
  uuid: string,
): Optional<T, T['tabs'][number]> {
  return Optic.id<T>()
    .compose(tabsLens<T>())
    .compose(
      filterForUuidOptic<T>(uuid) as Optional<T['tabs'], T['tabs'][number]>,
    );
}

/**
 * This function returns an optic that zooms into the criteria of a filter
 * with a given uuid
 * @param uuid  filter uuid
 */
export function filterCriteriaWithUuidOfCurrentUser<T extends Tababble>(
  uuid: string,
): Optional<T, T['tabs'][number]['criteria']> {
  return Optic.id<T>()
    .compose(filterWithUuidOfCurrentUser(uuid))
    .at('criteria')
    .nonNullable() satisfies Optional<T, T['tabs'][number]['criteria']>;
}

/**
 * This function returns an optic that zooms into the criteria of a filter
 * with a given uuid
 * @param uuid  filter uuid
 */
export function filterSortWithUuidOfCurrentUser<T extends Tababble>(
  uuid: string,
): Optic.Optional<T, NonNullable<T['tabs'][0]['sort']>> {
  return Optic.id<T>()
    .compose(filterWithUuidOfCurrentUser(uuid))
    .at('sort')
    .nonNullable();
}

/**
 * Optic on table configurations of given tabId
 *
 * @param uuid              filter uuid
 */
export function tableConfigurationForTabId<T extends Tababble>(
  uuid: string,
): Optional<
  T,
  ReadonlyArray<T['tabs'][0]['tableConfiguration']['columns'][number]>
> {
  return Optic.id<T>()
    .compose(filterWithUuidOfCurrentUser(uuid))
    .at('tableConfiguration')
    .at('columns') as Optional<
    T,
    ReadonlyArray<T['tabs'][0]['tableConfiguration']['columns'][number]>
  >;
}

/**
 * Replaces the criteria of the filter with the given uuid
 * @param listState         Old list state
 * @param uuid              filter uuid
 * @param criteria          New criteria
 */
export function replacePermanentFilterCriteriaOfCurrentUser<T extends Tababble>(
  listState: T,
  uuid: string,
  criteria: T['tabs'][number]['criteria'],
): T {
  return Optic.replace(filterCriteriaWithUuidOfCurrentUser<T>(uuid))(criteria)(
    listState,
  );
}

/**
 * Replaces the sort of the filter with the given uuid
 * @param listState         Old list state
 * @param uuid              filter uuid
 * @param sort              New sort
 */
export function replaceSortOfCurrentUser<T extends Tababble>(
  listState: T,
  uuid: string,
  sort: T['tabs'][number]['sort'],
): T {
  return Optic.replace(filterSortWithUuidOfCurrentUser<T>(uuid))(sort)(
    listState,
  );
}

/**
 * Returns a function that adds the given filter
 * @param newFilter    New filter
 */
export function addPermanentFilter<T extends Tababble>(
  newFilter: T['tabs'][number],
): (state: T) => T {
  return Optic.modify(tabsLens<T>())(
    (filters) =>
      [...filters, newFilter] satisfies (
        | TalentFilterFragment
        | VacancyFilterFragment
        | MatchFilterFragment
      )[] as T['tabs'],
  );
}

/**
 * Returns a function that sets the given filters
 * @param newFilters    New filter
 */
export function changeFilters<T extends Tababble>(
  newFilters: T['tabs'],
): (state: T) => T {
  return Optic.replace(tabsLens<T>())(newFilters);
}

/**
 * Changes the filter tab name with the given uuid
 * @param listState         Old list state
 * @param uuid              filter uuid
 * @param newTabName        New tab name
 */
export function changeFilterTabName<T extends Tababble>(
  listState: T,
  uuid: string,
  newTabName: string,
): T {
  return Optic.replace(filterNameWithUuidOfCurrentUser<T>(uuid))(newTabName)(
    listState,
  );
}

/**
 * Changes whether the filter is pinned or not
 * @param listState         Old list state
 * @param uuid              filter uuid
 * @param isPinned          New pin state
 */
export function setFilterPinnedState<T extends Tababble>(
  listState: T,
  uuid: string,
  isPinned: boolean,
): T {
  return Optic.replace(filterIsPinnedWithUuidOfCurrentUser<T>(uuid))(isPinned)(
    listState,
  );
}

/**
 * Optic on column visibility with id within given tab
 */
export function tableColumnVisibilityWithIdForTab<T extends Tababble>(
  uuid: string,
  columnId: string,
) {
  return Optic.id<T>()
    .compose(tableConfigurationForTabId<T>(uuid))
    .compose(Optic.findFirst((column) => column.columnId === columnId))
    .at('visible');
}

/**
 * Updates the column widths of the enabled columns. Expects the
 * number of elements of columns to be same as the number of enabled
 * columns
 * @param uuid        The tab uuid
 * @param newWidths   The widths of the new columns
 */
export function updateEnabledColumnWidthsForTabId<T extends Tababble>(
  uuid: string,
  newWidths: number[],
) {
  return Optic.modify(tableConfigurationForTabId<T>(uuid))(
    (currentColumns) =>
      ReadonlyArray.mapAccum(currentColumns, 0, (newWidthIndex, column) => [
        column.visible ? newWidthIndex + 1 : newWidthIndex,
        column.visible
          ? {
              ...column,
              width: newWidths[newWidthIndex],
            }
          : column,
      ])[1],
  );
}

/**
 * Defines how a default active tab is selected among the list of tabs
 * @param tabs  list of tabs, typically the saved tabs of the user
 */
export function chooseDefaultActiveTab<T extends States>(
  tabs: T['tabs'],
): T['tabs'][0] {
  return Option.getOrThrow(
    ReadonlyArray.head(tabs.filter((tab) => tab.pinned)),
  );
}

/**
 * Sets a valid active tab id if the current tab id is not part of the tabs.
 * @param state
 */
export function setValidActiveTab<T extends Tababble>(state: T): T {
  if (
    !state.tabs
      .filter((tab) => tab.pinned)
      .some((tab) => tab.uuid === state.activeTabId)
  ) {
    return {
      ...state,
      activeTabId: chooseDefaultActiveTab(
        filter(
          state.tabs,
          (
            tab:
              | VacancyFilterFragment
              | TalentFilterFragment
              | MatchFilterFragment,
          ) => tab.pinned,
        ),
      ).uuid,
    };
  }
  return state;
}

/**
 * This function returns an optic that zooms into the name of a filter
 * with a given uuid
 * @param uuid  filter uuid
 */
export function filterNameWithUuidOfCurrentUser<T extends Tababble>(
  uuid: string,
): Optional<T, string> {
  return Optic.id<T>().compose(filterWithUuidOfCurrentUser(uuid)).at('name');
}

/**
 * This function returns an optic that zooms into the pinning state of a filter
 * with a given uuid
 * @param uuid  filter uuid
 */
export function filterIsPinnedWithUuidOfCurrentUser<T extends Tababble>(
  uuid: string,
): Optional<T, boolean> {
  return Optic.id<T>().compose(filterWithUuidOfCurrentUser(uuid)).at('pinned');
}

/**
 * An optic that zooms in to the filter history array
 */
export const filterHistoryLens = <T extends States>() =>
  Optic.id<T>().at('filterHistory');

/**
 * Clears the filter history
 */
export const clearFilterHistory = <T extends States>() =>
  Optic.replace(filterHistoryLens<T>())([]);
