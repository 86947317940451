export function getDuration(duration: Duration): {
  duration: number;
  durationUnit: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds';
} {
  const days = duration.days ? duration.days : 0;
  const hours = duration.hours ? duration.hours : 0;
  const minutes = duration.minutes ? duration.minutes : 0;
  const seconds = duration.seconds ? duration.seconds : 0;
  const years = duration.years ? duration.years : 0;
  const months = duration.months ? duration.months : 0;

  if (years > 0) {
    return {
      duration: years,
      durationUnit: 'years',
    };
  }

  if (months > 0) {
    return {
      duration: months,
      durationUnit: 'months',
    };
  }

  if (days > 0) {
    return {
      duration: days,
      durationUnit: 'days',
    };
  }

  if (hours > 0) {
    return {
      duration: hours,
      durationUnit: 'hours',
    };
  }

  if (minutes > 0) {
    return {
      duration: minutes,
      durationUnit: 'minutes',
    };
  } else {
    return {
      duration: seconds,
      durationUnit: 'seconds',
    };
  }
}
