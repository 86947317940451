import { Injectable } from '@angular/core';
import { catchError, map, scan, shareReplay } from 'rxjs/operators';
import { applySubscriptionChangesToItems } from '../../shared/helpers/functions/applySubscriptionChangesToItems';
import { combineLatest } from 'rxjs';
import {
  GetBotGQL,
  UserBotFragment,
  UserListItemFragment,
  UsersSubscribeGQL,
} from '../../graphql/generated';
import { GraphQLService } from './graphql.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService {
  constructor(
    private getBotGQL: GetBotGQL,
    private usersSubscribeGQL: UsersSubscribeGQL,
    private graphQLService: GraphQLService,
  ) {}

  botUser$ = this.getBotGQL.fetch().pipe(
    map((res) => {
      return res.data['getBot'];
    }),
    shareReplay(1),
  );

  consultantUsers$ = this.usersSubscribeGQL
    .subscribe({ limit: 1000 }, { fetchPolicy: 'no-cache' })
    .pipe(
      map((res) => ({
        ...res.data?.usersSubscribe,
        items: res.data?.usersSubscribe?.items || [],
        itemIds: res.data?.usersSubscribe?.itemIds || [],
        changedItems: res.data?.usersSubscribe?.changedItems || [],
        removedItems: res.data?.usersSubscribe?.removedItems || [],
        addedItems: res.data?.usersSubscribe?.addedItems || [],
      })),
      scan((acc, result) =>
        applySubscriptionChangesToItems(acc, result, '_id'),
      ),
      // create new array to trigger angular change-detection
      map((res) => [...res.items]),
      shareReplay(1),
      catchError((err) => {
        this.graphQLService.handleError(err);
        throw err;
      }),
    );

  consultantsById$ = combineLatest([this.consultantUsers$, this.botUser$]).pipe(
    map(([users, botUser]) => {
      return users.reduce(
        (acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        },
        {
          [botUser._id]: botUser,
        } as Record<string, UserListItemFragment | UserBotFragment>,
      );
    }),
  );

  consultantsICanAssign$ = this.consultantUsers$.pipe(
    map((users) => users.filter((user) => user.iCanAssignToVacancy)),
  );
}
