// An internal reference of the `window.Userback` object which will
// be deleted from the `window` scope when using this module.
let USERBACK;
/* eslint-enable no-unused-vars */
// internal variable for storing a pending load of the widget to prevent loading the widget twice
// When undefined, the widget is not currently loading.
let UBLoadingPromise;
/*
 * UserbackWidgetLoader
 *
 * Provides a type-safe interface for initializing and retrieving the Userback object
 * @param token - The Userback token to use for initialisation
 * @param ubOptions - Optional configuration options for the Userback widget
 * @returns A promise that resolves to the UserbackWidget object
 */
function UserbackWidgetLoader(token, ubOptions) {
  if (UBLoadingPromise) return UBLoadingPromise;
  UBLoadingPromise = new Promise((resolve, reject) => {
    // Validation
    const error = e => {
      UBLoadingPromise = undefined;
      return reject(typeof e === 'string' ? new Error(e) : e);
    };
    if (typeof USERBACK !== 'undefined') {
      // eslint-disable-next-line no-console
      console.debug('Userback widget loaded twice, canceling initialisation');
      return resolve(USERBACK);
    }
    if (!token) {
      return error('A valid token must be provided from https://userback.io');
    }
    // Defaults
    const opts = typeof ubOptions === 'undefined' ? {} : ubOptions;
    const ubDomain = (opts === null || opts === void 0 ? void 0 : opts.domain) || 'userback.io';
    // Custom options
    window.Userback = {
      request_url: `https://api.${ubDomain}`
    };
    if (opts === null || opts === void 0 ? void 0 : opts.autohide) {
      if (!opts.widget_settings) {
        opts.widget_settings = {};
      }
      opts.widget_settings.trigger_type = opts.autohide ? 'api' : 'page_load';
    }
    // When the script tag is finished loading, we will move the `window.Userback` reference to
    // this local module and then provide it back as a promise resolution.
    function onload() {
      if (typeof window.Userback === 'undefined') {
        return error('`window.Userback` was somehow deleted while loading!');
      }
      window.Userback.init(token, Object.assign(Object.assign({}, opts), {
        on_init: () => {
          USERBACK = window.Userback;
          // @TODO: Cannot remove window.Userback as there are references inside the widget to it
          // delete window.Userback
          if (typeof (opts === null || opts === void 0 ? void 0 : opts.on_init) === 'function') {
            opts.on_init();
          }
          // Monkeypatch Userback.destroy to ensure we keep our USERBACK reference in sync
          const origDestroy = USERBACK.destroy;
          USERBACK.destroy = function proxyDestroy() {
            origDestroy();
            USERBACK = undefined;
            UBLoadingPromise = undefined;
          };
          return resolve(USERBACK);
        }
      }));
      return true;
    }
    // Create and inject the <script/> tag to start loading Userback
    const script = document.createElement('script');
    script.src = `https://static.${ubDomain}/widget/v1.js`;
    script.async = true;
    script.onload = onload;
    script.addEventListener('error', error);
    document.body.appendChild(script);
    return true;
  });
  return UBLoadingPromise;
}
/**
 * Returns the UserbackWidget if it has been initialised
 * */
function getUserback() {
  return USERBACK;
}
export { UserbackWidgetLoader as default, getUserback };