<ui-library-select
  *ngrxLet="consultants$ as consultants"
  [ngModel]="selectedConsultantKey$ | async"
  [isSingleSelect]="true"
  [disabled]="isDisabled"
  (ngModelChange)="onSelectedConsultantChanged($event)"
  [collapsedSelectedItemComponent]="selectedComponent"
  [expandedSelectedItemComponent]="selectedComponent">
  <ui-library-textbox-list-item [actionGenerator]="searchFilter" />
  <ui-library-selection-list [behavior]="singleSelectBehavior">
    <ng-template #noItemSelectedTemplate
      ><!-- That case does not exist --></ng-template
    >
    <app-consultant-select-list-item
      *ngFor="let consultant of consultants.sameBranch"
      [_value]="consultant._id"
      [label]="consultant" />
    <div
      *ngIf="consultants.sameBranch.length && consultants.otherBranches.length"
      class="uj-h-[1px] uj-bg-border uj-mx-d"></div>
    <app-consultant-select-list-item
      *ngFor="let consultant of consultants.otherBranches"
      [_value]="consultant._id"
      [label]="consultant" />
  </ui-library-selection-list>
</ui-library-select>
