import { Optional } from '@fp-ts/optic';
import { VacancyCriteriaItemFragment } from '../../graphql/generated';
import {
  VacanciesListState,
  VacanciesListStateColumnConfiguration,
} from './vacancies-list-state.types';
import { ReadonlyArray } from 'effect';
import {
  addPermanentFilter,
  changeFilters,
  changeFilterTabName,
  chooseDefaultActiveTab,
  filterCriteriaWithUuidOfCurrentUser,
  filterIsPinnedWithUuidOfCurrentUser,
  filterNameWithUuidOfCurrentUser,
  filterSortWithUuidOfCurrentUser,
  filterWithUuidOfCurrentUser,
  replacePermanentFilterCriteriaOfCurrentUser,
  replaceSortOfCurrentUser,
  setFilterPinnedState,
  setValidActiveTab,
  tableColumnVisibilityWithIdForTab,
  tableConfigurationColumnsOfFilterLens,
  tableConfigurationForTabId,
  updateEnabledColumnWidthsForTabId,
} from '../advanced-data-list/advanced-data-list.optics';
import { Tababble } from '../tab-management/state.types';

/**
 * An optic that zooms in to the table configuration of a vacancy filter.
 */
export const tableConfigurationColumnsOfVacancyFilterLens: () => Optional<
  VacanciesListState['tabs'][0],
  ReadonlyArray<VacanciesListStateColumnConfiguration>
> = tableConfigurationColumnsOfFilterLens<VacanciesListState>;

/**
 * This function returns an optic that zooms into a vacancy filter with a given uuid
 * @param uuid  Vacancy filter uuid
 */
export const vacancyFilterWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<VacanciesListState, VacanciesListState['tabs'][0]> =
  filterWithUuidOfCurrentUser<VacanciesListState>;

/**
 * This function returns an optic that zooms into the criteria of a vacancy filter
 * with a given uuid
 * @param uuid  Vacancy filter uuid
 */
export const vacancyFilterCriteriaWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<VacanciesListState, VacanciesListState['tabs'][0]['criteria']> =
  filterCriteriaWithUuidOfCurrentUser<VacanciesListState>;

/**
 * This function returns an optic that zooms into the criteria of a vacancy filter
 * with a given uuid
 * @param uuid  Vacancy filter uuid
 */
export const vacancyFilterSortWithUuidOfCurrentUser =
  filterSortWithUuidOfCurrentUser<VacanciesListState>;

/**
 * This function returns an optic that zooms into the name of a vacancy filter
 * with a given uuid
 * @param uuid  Vacancy filter uuid
 */
export const vacancyFilterNameWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<VacanciesListState, string> =
  filterNameWithUuidOfCurrentUser<VacanciesListState>;

/**
 * This function returns an optic that zooms into the pinning state of a vacancy filter
 * with a given uuid
 * @param uuid  Vacancy filter uuid
 */
export const vacancyFilterIsPinnedWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<VacanciesListState, boolean> =
  filterIsPinnedWithUuidOfCurrentUser<VacanciesListState>;

/**
 * Optic on table configurations of given tabId
 *
 * @param uuid              Vacancy filter uuid
 */
export const vacancyTableConfigurationForTabId: (
  uuid: string,
) => Optional<
  VacanciesListState,
  ReadonlyArray<VacanciesListStateColumnConfiguration>
> = tableConfigurationForTabId<VacanciesListState>;

/**
 * Replaces the criteria of the vacancy filter with the given uuid
 * @param vacanciesListState  Old vacancy list state
 * @param uuid              Vacancy filter uuid
 * @param criteria          New criteria
 */
export const replacePermanentVacancyFilterCriteriaOfCurrentUser: (
  vacanciesListState: VacanciesListState,
  uuid: string,
  criteria: readonly VacancyCriteriaItemFragment[],
) => VacanciesListState =
  replacePermanentFilterCriteriaOfCurrentUser<VacanciesListState>;

/**
 * Replaces the sort of the vacancy filter with the given uuid
 * @param VacanciesListState  Old vacancies list state
 * @param uuid              Vacancy filter uuid
 * @param sort              New sort
 */
export const replaceVacancySortOfCurrentUser: <State extends Tababble>() => (
  state: State,
  uuid: string,
  sort: State['tabs'][number]['sort'],
) => State = <State extends Tababble>() => replaceSortOfCurrentUser<State>;

/**
 * Returns a function that adds the given vacancy filter
 * @param newVacancyFilter    New Vacancy filter
 */
export const addPermanentVacancyFilter: (
  newVacancyFilter: VacanciesListState['tabs'][0],
) => (state: VacanciesListState) => VacanciesListState =
  addPermanentFilter<VacanciesListState>;

/**
 * Returns a function that sets the given vacancy filters
 * @param newVacancyFilters    New Vacancy filter
 */
export const changeVacancyFilters: (
  newVacancyFilters: VacanciesListState['tabs'],
) => (state: VacanciesListState) => VacanciesListState =
  changeFilters<VacanciesListState>;

/**
 * Changes the vacancy filter tab name with the given uuid
 * @param vacanciesListState  Old vacancies list state state
 * @param uuid              Vacancy filter uuid
 * @param newTabName        New tab name
 */
export const changeVacancyFilterTabName: (
  vacanciesListState: VacanciesListState,
  uuid: string,
  newTabName: string,
) => VacanciesListState = changeFilterTabName<VacanciesListState>;

/**
 * Changes whether the vacancy filter is pinned or not
 * @param VacanciesListState  Old vacancies list state
 * @param uuid              Vacancy filter uuid
 * @param isPinned          New pin state
 */
export const setVacancyFilterPinnedState: (
  VacanciesListState: VacanciesListState,
  uuid: string,
  isPinned: boolean,
) => VacanciesListState = setFilterPinnedState<VacanciesListState>;

/**
 * Optic on column visibility with id within given tab
 */
export const vacancyTableColumnVisibilityWithIdForVacancyTab =
  tableColumnVisibilityWithIdForTab<VacanciesListState>;

/**
 * Updates the column widths of the enabled columns. Expects the
 * number of elements of columns to be same as the number of enabled
 * columns
 * @param uuid        The tab uuid
 * @param newWidths   The widths of the new columns
 */
export const updateEnabledVacancyColumnWidthsForTabId =
  updateEnabledColumnWidthsForTabId<VacanciesListState>;

/**
 * Defines how a default active tab is selected among the list of tabs
 * @param tabs  list of tabs, typically the saved tabs of the user
 */
export const chooseDefaultActiveVacancyTab: (
  tabs: VacanciesListState['tabs'],
) => VacanciesListState['tabs'][0] = chooseDefaultActiveTab<VacanciesListState>;

/**
 * Sets a valid active tab id if the current tab id is not part of the tabs.
 * @param state
 */
export const setValidActiveVacancyTab: (
  state: VacanciesListState,
) => VacanciesListState = setValidActiveTab<VacanciesListState>;
