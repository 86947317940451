import {
  Vacancy,
  VacancyDetailFragment,
  VacancyListItemFragment,
  VacancyStatusEnum,
} from '../../../graphql/generated';
import { intervalToDuration } from 'date-fns';
import { getDuration } from './getDuration';
import { DotColor } from '@intemp/unijob-ui2';

export function getStatusWithDuration(
  vacancy: Vacancy | VacancyListItemFragment | VacancyDetailFragment,
  variant: 'dot-text' | 'avatar' = 'dot-text',
) {
  let date: Date | undefined;
  let duration: number | undefined;
  let durationUnit:
    | 'years'
    | 'months'
    | 'days'
    | 'hours'
    | 'minutes'
    | 'seconds'
    | undefined;

  let dateTranslationKey: string | undefined;

  const dotColor: DotColor =
    vacancy.status === VacancyStatusEnum.LIVE
      ? 'positive'
      : vacancy.status === VacancyStatusEnum.SCHEDULED
        ? 'warning-2'
        : 'grey';

  const inverted = vacancy.status === VacancyStatusEnum.DRAFT;

  const now = new Date();
  switch (vacancy.status) {
    case VacancyStatusEnum.DRAFT:
      date = undefined;
      duration = undefined;
      dateTranslationKey = undefined;
      durationUnit = undefined;
      break;
    case VacancyStatusEnum.LIVE:
      date = vacancy.derivedFields.lastVacancyNumberPublishedAt
        ? new Date(vacancy.derivedFields.lastVacancyNumberPublishedAt)
        : now;
      if (date) {
        dateTranslationKey = 'sinceDaysPublished';
        const start = new Date(date);
        const durationSincePublished = intervalToDuration({
          start,
          end: now,
        });
        const result = getDuration(durationSincePublished);
        duration = result.duration;
        durationUnit = result.durationUnit;
      }
      break;
    case VacancyStatusEnum.SCHEDULED:
      date = vacancy.uniBaseX?.publishByDate
        ? new Date(vacancy.uniBaseX?.publishByDate)
        : undefined;
      if (date) {
        dateTranslationKey = 'waitingUntil';
        const durationUntilPublish = intervalToDuration({
          start: now,
          end: new Date(date),
        });
        const result = getDuration(durationUntilPublish);
        duration = result.duration;
        durationUnit = result.durationUnit;
      }
      break;
    case VacancyStatusEnum.ARCHIVED:
      date = vacancy.archivedAt ? new Date(vacancy.archivedAt) : now;
      if (date) {
        dateTranslationKey = 'sinceArchived';
        const durationArchivedSince = intervalToDuration({
          start: new Date(date),
          end: now,
        });
        const result = getDuration(durationArchivedSince);
        duration = result.duration;
        durationUnit = result.durationUnit;
      }
      break;
    case VacancyStatusEnum.DELETED:
      date = vacancy.deletedAt ? new Date(vacancy.deletedAt) : now;
      if (date) {
        dateTranslationKey = 'sinceDeleted';
        const durationDeletedSince = intervalToDuration({
          start: new Date(date),
          end: now,
        });
        const result = getDuration(durationDeletedSince);
        duration = result.duration;
        durationUnit = result.durationUnit;
      }
      break;
    default:
      date = undefined;
      duration = undefined;
      dateTranslationKey = undefined;
      durationUnit = undefined;
      break;
  }

  return {
    date,
    duration,
    durationUnit,
    dateTranslationKey,
    variant,
    dotColor,
    inverted,
  };
}
