import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  Type,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatestWith,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { UserListItemFragment } from '../../../graphql/generated';
import { ConsultantService } from '../../../core/services/consultant.service';
import { UserService } from '../../../models/shared/user/user.service';
import { filterIsNotEmpty } from '../../helpers/functions/filterIsNotEmpty';
import { TemplateSingleSelectComponent } from '../template-single-select/template-single-select.component';
import { ConsultantSelectSelectedItemCollapsedComponent } from './consultant-select-selected-item-collapsed/consultant-select-selected-item-collapsed.component';
import {
  ISelectedItem,
  NewStateGenerator,
  Select2State,
  singleSelectBehavior,
} from '@intemp/unijob-ui2';
import { HashMap, pipe, ReadonlyArray, String } from 'effect';

@Component({
  selector: 'app-consultant-select',
  templateUrl: './consultant-select.component.html',
  styleUrls: ['./consultant-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConsultantSelectComponent),
      multi: true,
    },
  ],
})
export class ConsultantSelectComponent
  implements ControlValueAccessor, OnDestroy
{
  @ViewChild(TemplateSingleSelectComponent)
  templateSingleSelectComponent?: TemplateSingleSelectComponent;

  selectedBranchId$ = new BehaviorSubject<string | undefined>(undefined);

  @Input() set selectedBranchId(branchId: string | undefined) {
    this.selectedBranchId$.next(branchId);
  }

  @Input({ required: true }) set allConsultants(
    consultants: UserListItemFragment[],
  ) {
    this.allConsultants$.next(consultants);
  }

  destroyed$ = new Subject<void>();
  onChange = (_: any) => {};
  onTouched = () => {};
  isDisabled = false;

  allConsultants$ = new ReplaySubject<UserListItemFragment[]>(1);

  consultants: UserListItemFragment[] = [];

  // New select implementation
  selectedComponent: Type<ISelectedItem<string, UserListItemFragment>> =
    ConsultantSelectSelectedItemCollapsedComponent;

  selectedConsultantId?: string;
  public selectedConsultant$ = new BehaviorSubject<
    UserListItemFragment | undefined
  >(undefined);
  public selectedConsultantKey$ = this.selectedConsultant$.pipe(
    filter(filterIsNotEmpty),
    map((consultant) => consultant._id),
  );

  readonly searchFilter: (
    value: string,
  ) => NewStateGenerator<string, UserListItemFragment, string> =
    (searchValue: string) =>
    (state: Select2State<string, UserListItemFragment, string>) => ({
      ...state,
      allItems: state.allItems,
      hiddenItems: pipe(
        state.allItems,
        HashMap.filter<string, UserListItemFragment>(
          (value) =>
            !String.includes(String.toLowerCase(searchValue))(
              String.toLowerCase(value.profile.firstName),
            ) &&
            !String.includes(String.toLowerCase(searchValue))(
              String.toLowerCase(value.profile.lastName),
            ) &&
            !String.includes(String.toLowerCase(searchValue))(
              String.toLowerCase(value.profile.position ?? ''),
            ),
        ),
        HashMap.keys,
        ReadonlyArray.fromIterable,
      ),
      decoration: searchValue,
    });

  consultants$: Observable<{
    sameBranch: UserListItemFragment[];
    otherBranches: UserListItemFragment[];
  }> = this.allConsultants$.pipe(
    combineLatestWith(this.userService.user$),
    map(([consultants, currentUser]) => {
      if (!currentUser) return { sameBranch: [], otherBranches: [] };
      const sameBranch: UserListItemFragment[] = [];
      const otherBranches: UserListItemFragment[] = [];
      consultants.forEach((c) => {
        if (
          c.customerBranches?.some((b) =>
            currentUser.customerBranches?.includes(b),
          )
        ) {
          sameBranch.push(c);
        } else {
          otherBranches.push(c);
        }
      });
      return { sameBranch, otherBranches };
    }),
    shareReplay(1),
  );

  constructor(
    private userService: UserService,
    private consultantService: ConsultantService,
  ) {
    this.allConsultants$.pipe(takeUntil(this.destroyed$)).subscribe((c) => {
      this.consultants = c;
      this.updateSelectedConsultant();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(consultantId?: string): void {
    this.selectedConsultantId = consultantId;
    this.updateSelectedConsultant();
  }

  onSelectedConsultantChanged(consultantId: UserListItemFragment['_id']) {
    if (this.selectedConsultantId !== consultantId) {
      this.onChange(consultantId);
      this.selectedConsultantId = consultantId;
      this.allConsultants$
        .pipe(take(1))
        .subscribe((consultants) =>
          this.selectedConsultant$.next(
            consultants.find((consultant) => consultant._id === consultantId),
          ),
        );
    }
  }

  updateSelectedConsultant() {
    this.selectedConsultant$.next(
      this.consultants.find((c) => c._id === this.selectedConsultantId),
    );
  }

  toggleOptions() {
    this.templateSingleSelectComponent?.toggleOptions();
  }

  protected readonly singleSelectBehavior = singleSelectBehavior;
}
