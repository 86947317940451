import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { SharedFormsModule } from '../shared-forms/shared-forms.module';
import { VacanciesStatusIndicatorComponent } from './vacancies-status-indicator.component';
import { DotComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [VacanciesStatusIndicatorComponent],
  imports: [SharedDefaultModule, SharedFormsModule, DotComponent],
  exports: [VacanciesStatusIndicatorComponent],
})
export class VacanciesStatusIndicatorModule {}
