import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  VacancyCriteriaFieldEnum,
  VacancyCriteriaItemFragment,
  VacancyFilterInput,
  VacancyListItemFragment,
} from '../../graphql/generated';
import {
  ModularSearchChip,
  ModularSearchOption,
} from '../../shared/modules/modular-searchbar/types/modular-search-options';
import { VacanciesListState } from './vacancies-list-state.types';
import { tabActions } from '../tab-management/actions';

export const VacanciesListActions = createActionGroup({
  source: 'Vacancies List',
  events: {
    'Temporary Query changed': props<{
      searchChips: ModularSearchChip<VacancyCriteriaFieldEnum>[];
    }>(),
    'Criteria Params changed': props<{
      searchChips: ModularSearchChip<VacancyCriteriaFieldEnum>[];
    }>(),
    'List Vacancy Entered View': props<{
      id: string;
    }>(),
    'Vacancy Received for List': props<{
      vacancy: VacancyListItemFragment;
    }>(),
    'Vacancies Page entered': emptyProps(),
    'Vacancies Page left': emptyProps(),
    'Load More Vacancies Clicked': emptyProps(),
    'Tab initialized': emptyProps(),
    'Search history Opened': emptyProps(),
    'Search history Closed': emptyProps(),
  },
});

export const VacanciesApiActions = createActionGroup({
  source: 'Vacancies API',
  events: {
    'Loading Vacancy Ids succeeded': props<{
      vacancyIds: ReadonlyArray<string>;
      totalCount: number;
    }>(),
    'Loading Vacancies errored': emptyProps(),
    'Loading Vacancies started': emptyProps(),
    'Received Vacancy List Item Data': props<VacancyListItemFragment>(),
    'Loading pinned tab count succeeded': props<{
      tabId: string;
      count: number;
    }>(),
    'Loading pinned tab count errored': props<{ tabId: string }>(),
    'Loading search options succeeded': props<{
      searchOptions: ModularSearchOption<VacancyCriteriaFieldEnum>[];
    }>(),
    'Vacancy Search History Cleared': emptyProps(),
    'Vacancy Search History Adding Started': props<{
      filter: VacancyFilterInput;
    }>(),
    'Vacancy Search History Adding Succeeded': props<{
      updatedFilterHistory: ReadonlyArray<
        ReadonlyArray<VacancyCriteriaItemFragment>
      >;
    }>(),
    'Vacancy Search History Adding Errored': props<{
      error: any;
    }>(),
    'Vacancy Search History Clearing Started': emptyProps(),
    'Vacancy Search History Clearing Succeeded': emptyProps(),
    'Vacancy Search History Clearing Errored': props<{
      error: any;
    }>(),
  },
});

export const vacancyListTabableActions = tabActions<
  VacanciesListState,
  'vacancy'
>('vacancy');
