import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import {
  Vacancy,
  VacancyDetailFragment,
  VacancyListItemFragment,
  VacancyStatusEnum,
} from '../../../graphql/generated';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { getStatusWithDuration } from '../../helpers/functions/getStatusWithDuration';

@Component({
  selector: 'app-vacancies-status-indicator',
  templateUrl: './vacancies-status-indicator.component.html',
  styleUrls: ['./vacancies-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacanciesStatusIndicatorComponent {
  vacancy = input<Vacancy | VacancyListItemFragment | VacancyDetailFragment>();
  variant = input<'dot-text' | 'avatar'>('dot-text');
  textSize = input<'small' | 'medium'>('small');

  showDate = input(false, {
    transform: (value: boolean | string | undefined) =>
      coerceBooleanProperty(value),
  });
  extendedFormat = input(false, {
    transform: (value: BooleanInput) => coerceBooleanProperty(value),
  });

  hasChanges = computed(() => {
    const vacancyStatus = this.vacancy()?.status;
    if (
      vacancyStatus === VacancyStatusEnum.LIVE ||
      vacancyStatus === VacancyStatusEnum.SCHEDULED
    ) {
      const unpublishedChanges = this.vacancy()?.uniBaseX?.unpublishedChanges;
      return unpublishedChanges && unpublishedChanges.length > 0 ? true : false;
    }

    return false;
  });

  computedValues = computed(() => {
    const vacancy = this.vacancy();
    if (!vacancy) return;
    return getStatusWithDuration(vacancy, this.variant());
  });
}
