import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateSingleSelectComponent } from './template-single-select.component';
import { TemplateSingleSelectOptionComponent } from './template-single-select-option/template-single-select-option.component';
import { IconModule, UnijobModule } from '@intemp/unijob-ui';
import { I18NextModule } from 'angular-i18next';
import { AvatarComponent, ButtonTextComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [
    TemplateSingleSelectComponent,
    TemplateSingleSelectOptionComponent,
  ],
  exports: [TemplateSingleSelectComponent, TemplateSingleSelectOptionComponent],
  imports: [
    AvatarComponent,
    CommonModule,
    IconModule,
    UnijobModule,
    I18NextModule,
    ButtonTextComponent,
  ],
})
export class TemplateSingleSelectModule {}
