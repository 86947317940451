import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarSize } from '@intemp/unijob-ui/lib/components/avatar/avatar.component';
import { AnyUser } from '../../../models/types';
import { UserAvatarService } from '../../../user-avatar.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  objectUrl?: string;

  constructor(private userAvatarService: UserAvatarService) {}

  @Input() set user(user: AnyUser | undefined) {
    if (!user) return;
    this._user = user;
    const avatarObjectUrl = this.userAvatarService.getAvatarObjectUrl(user);
    if (avatarObjectUrl) {
      this.objectUrl = avatarObjectUrl;
    }
  }
  get user() {
    return this._user;
  }
  _user?: AnyUser;
  @Input() onlyFirstName = false;
  @Input() size: AvatarSize = 'm';

  @Input()
  get interactive() {
    return this._interactive;
  }
  set interactive(value: BooleanInput) {
    this._interactive = coerceBooleanProperty(value);
  }
  _interactive = false;
}
