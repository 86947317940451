import { LanguageService } from '../../../../core/services/language.service';

export const getActiveLanguageAsVacancyLanguage = (
  languageService: LanguageService,
): string => {
  const lang = languageService.getActiveLanguage();
  if (lang) {
    return lang.toLowerCase();
  }
  return 'de';
};
