import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import i18next from 'i18next';
import { SupportedLanguage, UpdateUserGQL } from '../../graphql/generated';
import { UserService } from '../../models/shared/user/user.service';
import { UserbackService } from './userback.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private router: Router,
    private updateUserGQL: UpdateUserGQL,
    public userService: UserService,
    private userbackService: UserbackService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    const initialLanguage = this.getActiveLanguage();
    this.document.documentElement.lang = initialLanguage;
    this.userbackService.updateWidgetLanguage(initialLanguage).then();
  }

  defaultLanguage: SupportedLanguage = SupportedLanguage.DE;
  languageWhitelist: SupportedLanguage[] = [
    SupportedLanguage.EN,
    SupportedLanguage.DE,
    SupportedLanguage.FR,
  ];

  setLanguage(isoCode: string, force = false) {
    if (force || this.getActiveLanguage() !== isoCode) {
      this.document.documentElement.lang = isoCode;
      this.userbackService.updateWidgetLanguage(isoCode).then();
      this.router
        .navigate(['language'], {
          queryParams: { redirect: this.router.url, language: isoCode },
        })
        .then();
    }
  }

  async applyLanguage(isoCode: string) {
    localStorage.setItem('lang', isoCode);
    await i18next.changeLanguage(isoCode.toLowerCase()).then();
    await firstValueFrom(this.updateUserLanguage(isoCode));
    return;
  }

  getActiveLanguage(): string {
    const lang = localStorage.getItem('lang');
    const browserLanguage = navigator.language.split('-')?.[0]?.toUpperCase();
    return (
      lang ||
      (this.languageWhitelist.includes(browserLanguage as SupportedLanguage)
        ? browserLanguage
        : this.defaultLanguage)
    );
  }

  getUserLanguage(): void {
    this.userService.getUser().then((user) => {
      if (user.profile.language) {
        this.setLanguage(user.profile.language);
      }
    });
  }

  updateUserLanguage(isoCode: string): Observable<any> {
    return this.updateUserGQL.mutate({
      userInput: {
        profile: {
          language: isoCode,
        },
      },
    });
  }
}
