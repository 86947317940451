import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { AvatarComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [CommonModule, AvatarComponent, SharedDefaultModule],
  exports: [UserAvatarComponent],
})
export class UserAvatarModule {}
