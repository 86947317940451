import {
  SortDirEnum,
  VacancySortEnum,
  VacancyTableColumnIdEnum,
} from '../../../graphql/generated';
import {
  ColumnSortKeys,
  VacancyColumnTitles,
} from '../../../pages/vacancies/vacancy.types';
import { VacanciesListStateColumnConfiguration } from '../../../state/vacancies-list/vacancies-list-state.types';

export const initialVacancyTableConfiguration: ReadonlyArray<VacanciesListStateColumnConfiguration> =
  [
    {
      columnId: VacancyTableColumnIdEnum.TITLE,
      visible: true,
      width: 400,
    },
    {
      columnId: VacancyTableColumnIdEnum.VACANCY_NUMBER,
      visible: false,
      width: 170,
    },

    {
      columnId: VacancyTableColumnIdEnum.STATUS,
      visible: false,
      width: 300,
    },
    {
      columnId: VacancyTableColumnIdEnum.RESPONSIBLE_USER,
      visible: true,
      width: 240,
    },
    {
      columnId: VacancyTableColumnIdEnum.BRANCH,
      visible: false,
      width: 180,
    },

    {
      columnId: VacancyTableColumnIdEnum.EMPLOYMENT_TYPE,
      visible: false,
      width: 170,
    },
    {
      columnId: VacancyTableColumnIdEnum.CONTRACT_TERM,
      visible: false,
      width: 170,
    },

    {
      columnId: VacancyTableColumnIdEnum.EMPLOYMENT_START,
      visible: false,
      width: 240,
    },
    {
      columnId: VacancyTableColumnIdEnum.COMPANY,
      visible: true,
      width: 180,
    },
    {
      columnId: VacancyTableColumnIdEnum.PUBLISHED_WORK_LOCATION,
      visible: true,
      width: 220,
    },
    {
      columnId: VacancyTableColumnIdEnum.STREET,
      visible: false,
      width: 200,
    },
    {
      columnId: VacancyTableColumnIdEnum.ZIP,
      visible: false,
      width: 85,
    },
    {
      columnId: VacancyTableColumnIdEnum.CONTACT,
      visible: false,
      width: 180,
    },
    {
      columnId: VacancyTableColumnIdEnum.PROMOTION_ADDON,
      visible: true,
      width: 290,
    },
    {
      columnId: VacancyTableColumnIdEnum.TOTAL_APPLICATIONS,
      visible: false,
      width: 180,
    },
    {
      columnId: VacancyTableColumnIdEnum.TOTAL_VIEWED,
      visible: false,
      width: 100,
    },
    {
      columnId: VacancyTableColumnIdEnum.ACTIVE_TV_PROMOTION,
      visible: false,
      width: 420,
    },
    {
      columnId: VacancyTableColumnIdEnum.PRIO_BOOST,
      visible: false,
      width: 180,
    },
    {
      columnId: VacancyTableColumnIdEnum.UPDATED_AT_FOR_USER,
      visible: true,
      width: 180,
    },
    {
      columnId: VacancyTableColumnIdEnum.UPDATED_BY,
      visible: false,
      width: 240,
    },
    {
      columnId: VacancyTableColumnIdEnum.PUBLICATION_CHANGED_AT,
      visible: false,
      width: 180,
    },
    {
      columnId: VacancyTableColumnIdEnum.CREATED_AT,
      visible: false,
      width: 220,
    },
    {
      columnId: VacancyTableColumnIdEnum.CREATED_BY,
      visible: false,
      width: 240,
    },
    {
      columnId: VacancyTableColumnIdEnum.LANGUAGE,
      visible: false,
      width: 220,
    },
  ];

/**
 * Defines the sort keys belonging to a column, if one exists and the table should be sortable by this column
 */
export const sortableTableColumns: Partial<ColumnSortKeys> = {
  [VacancyTableColumnIdEnum.TITLE]: VacancySortEnum.TITLE,
  [VacancyTableColumnIdEnum.VACANCY_NUMBER]: VacancySortEnum.VACANCY_NUMBER,
  [VacancyTableColumnIdEnum.STREET]: VacancySortEnum.JOB_LOCATION_STREET,
  [VacancyTableColumnIdEnum.ZIP]: VacancySortEnum.JOB_LOCATION_ZIP,
  [VacancyTableColumnIdEnum.COMPANY]: VacancySortEnum.JOB_COMPANY_LOCATION,
  [VacancyTableColumnIdEnum.CONTACT]: VacancySortEnum.JOB_COMPANY_CONTACT,
  [VacancyTableColumnIdEnum.UPDATED_AT_FOR_USER]:
    VacancySortEnum.UPDATED_AT_FOR_USER,
  [VacancyTableColumnIdEnum.CREATED_AT]: VacancySortEnum.CREATED_AT,
  [VacancyTableColumnIdEnum.RESPONSIBLE_USER]:
    VacancySortEnum.RESPONSIBLE_USER_NAME,
  [VacancyTableColumnIdEnum.BRANCH]: VacancySortEnum.CUSTOMER_BRANCH_NAME,
  [VacancyTableColumnIdEnum.STATUS]: VacancySortEnum.STATUS,
  [VacancyTableColumnIdEnum.PUBLICATION_CHANGED_AT]:
    VacancySortEnum.PUBLICATION_CHANGED_AT,
  [VacancyTableColumnIdEnum.PROMOTION_ADDON]: VacancySortEnum.PROMOTION_ADDON,
  [VacancyTableColumnIdEnum.EMPLOYMENT_START]: VacancySortEnum.EMPLOYMENT_START,
  [VacancyTableColumnIdEnum.EMPLOYMENT_TYPE]: VacancySortEnum.EMPLOYMENT_TYPE,
  [VacancyTableColumnIdEnum.CONTRACT_TERM]: VacancySortEnum.CONTRACT_TERM,
  [VacancyTableColumnIdEnum.ACTIVE_TV_PROMOTION]:
    VacancySortEnum.ACTIVE_TV_PROMOTION,
  [VacancyTableColumnIdEnum.TOTAL_APPLICATIONS]:
    VacancySortEnum.TOTAL_APPLICATIONS,
  [VacancyTableColumnIdEnum.TOTAL_VIEWED]: VacancySortEnum.TOTAL_VIEWED,
  [VacancyTableColumnIdEnum.PRIO_BOOST]: VacancySortEnum.PRIO_BOOST,
  [VacancyTableColumnIdEnum.PUBLISHED_WORK_LOCATION]:
    VacancySortEnum.PUBLISHED_WORK_LOCATION,
  [VacancyTableColumnIdEnum.CREATED_BY]: VacancySortEnum.CREATED_BY_USER_NAME,
  [VacancyTableColumnIdEnum.UPDATED_BY]: VacancySortEnum.UPDATED_BY_USER_NAME,
  // ACTIVE_TV_PROMOTION
};

export const vacancyColumnTitles: VacancyColumnTitles = {
  [VacancyTableColumnIdEnum.TITLE]: 'title',
  [VacancyTableColumnIdEnum.VACANCY_NUMBER]: 'vacancyNumber',
  [VacancyTableColumnIdEnum.STREET]: 'streetAndNumber',
  [VacancyTableColumnIdEnum.ZIP]: 'zip',
  [VacancyTableColumnIdEnum.COMPANY]: 'jobCompany',
  [VacancyTableColumnIdEnum.CONTACT]: 'contact',
  [VacancyTableColumnIdEnum.UPDATED_AT_FOR_USER]: 'lastUpdatedAt',
  [VacancyTableColumnIdEnum.CREATED_AT]: 'createdAt',
  [VacancyTableColumnIdEnum.RESPONSIBLE_USER]: 'responsibleEmployee',
  [VacancyTableColumnIdEnum.BRANCH]: 'branch',
  [VacancyTableColumnIdEnum.STATUS]: 'status',
  [VacancyTableColumnIdEnum.PUBLICATION_CHANGED_AT]: 'publicationChangedAt',
  [VacancyTableColumnIdEnum.PROMOTION_ADDON]: 'addOnSearch',
  [VacancyTableColumnIdEnum.ACTIVE_TV_PROMOTION]: 'tvScreen',
  [VacancyTableColumnIdEnum.EMPLOYMENT_TYPE]: 'employment',
  [VacancyTableColumnIdEnum.EMPLOYMENT_START]: 'start',
  [VacancyTableColumnIdEnum.CONTRACT_TERM]: 'contractTerm',
  [VacancyTableColumnIdEnum.TOTAL_APPLICATIONS]: 'totalApplications',
  [VacancyTableColumnIdEnum.TOTAL_VIEWED]: 'totalViewed',
  [VacancyTableColumnIdEnum.PRIO_BOOST]: 'prioBoost',
  [VacancyTableColumnIdEnum.LANGUAGE]: 'language',
  [VacancyTableColumnIdEnum.PUBLISHED_WORK_LOCATION]: 'publishedWorkLocation',
  [VacancyTableColumnIdEnum.CREATED_BY]: 'createdBy',
  [VacancyTableColumnIdEnum.UPDATED_BY]: 'lastModifiedBy',
};

export const VACANCIES_DEFAULT_SORT_DIR = SortDirEnum.DESC;
export const VACANCIES_DEFAULT_SORT_BY = VacancySortEnum.UPDATED_AT_FOR_USER;
export const MIN_COLUMN_WIDTH = 100;
