import * as Either from 'effect/Either';
import { pipe } from 'effect/Function';
import * as Option from 'effect/Option';
import type { Predicate, Refinement } from 'effect/Predicate';
import * as ReadonlyArray from 'effect/ReadonlyArray';
import { Optional, optional } from '@fp-ts/optic';

/*
Returns an Optional to an element of an array if the element satisfies the predicate.
 */
export const firstElementBy: {
  <S extends A, B extends A, A = S>(
    refinement: Refinement<A, B>,
    message?: string,
  ): Optional<ReadonlyArray<A>, A>;
  <S extends A, A = S>(
    predicate: Predicate<A>,
    message?: string,
  ): Optional<ReadonlyArray<A>, A>;
} = <S>(
  predicate: Predicate<S>,
  message?: string,
): Optional<ReadonlyArray<S>, S> =>
  optional(
    (s) =>
      // @ts-ignore TODO: We did not do proper type checking at some point before - this needs to be fixed
      pipe(
        s,
        ReadonlyArray.findFirstIndex(predicate),
        Option.flatMap((i) => ReadonlyArray.get(s, i)),
        Either.fromOption(
          () => new Error(message ?? `No index matching predicate`),
        ),
      ),
    (a) => (s) =>
      pipe(
        s,
        ReadonlyArray.findFirstIndex(predicate),
        Option.flatMap((i) => ReadonlyArray.replaceOption(i, a)(s)),
        Either.fromOption(() => new Error(`No index matching predicate`)),
      ),
  );
