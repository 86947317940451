import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { catchError, filter, map, scan, shareReplay } from 'rxjs/operators';
import { GraphQLService } from '../../../core/services/graphql.service';
import { LanguageService } from '../../../core/services/language.service';
import {
  CurrencyEnum,
  LanguageProficiencyEnum,
  SalaryUnitEnum,
  UniBaseXVacancy,
  UniBaseXVacancyCreateInput,
  UserSelfFragment,
  VacanciesCountGQL,
  VacanciesCountQueryVariables,
  VacanciesSubscribeGQL,
  VacanciesSubscribeSubscriptionVariables,
  Vacancy,
  VacancyCreateGQL,
  VacancyCreateMutationVariables,
  VacancyEmploymentTypeEnum,
  VacancyHistoryListGQL,
  VacancyIdsSubscribeGQL,
  VacancyIdsSubscribeSubscriptionVariables,
  VacancyListItemFragment,
  VacancyListItemSubscribeGQL,
  VacancyQueryGQL,
  VacancyStatusEnum,
  VacancyStatusTransitionEnum,
  VacancySubscribeGQL,
  VacancyUpdateGQL,
  VacancyUpdateManyGQL,
  VacancyUpdateManyMutationVariables,
  VacancyUpdateMutationVariables,
} from '../../../graphql/generated';
import { applySubscriptionChangesToItems } from '../../../shared/helpers/functions/applySubscriptionChangesToItems';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../../shared/modules/global-sheets/global-sheets.service';
import { VacancyAllowedAction } from '../../../pages/vacancies/vacancy.types';
import { environment } from '../../../../environments/environment';
import { defaultLanguageEntryID } from '../../../shared/modules/vacancy-detail/constants/defaultLanguageEntryID.constants';
import { getActiveLanguageAsVacancyLanguage } from '../../../shared/modules/vacancy-detail/helpers/getActiveLanguageAsVacancyLanguage';
import { UserService } from '../../shared/user/user.service';
import { Writable } from 'type-fest';
import { CustomerBranchService } from '../customer-branch/customer-branch.service';
import { firstValueFrom } from 'rxjs';
import { getLanguageRequirementsTextOptions } from '../../../shared/modules/vacancy-detail/helpers/getLanguageRequirementsTextOptions';
import { Router } from '@angular/router';
import { userHasMatchingEnabled } from '../../../shared/helpers/functions/userHasMatchingEnabled';
import { PromiseQueue } from '../../../shared/helpers/classes/PromiseQueue';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export type StructuredVacancyAllowedActions = {
  secondaryActions: VacancyAllowedAction[];
  primaryActions: VacancyAllowedAction[];
  sheetActions: VacancyAllowedAction[];
};

@Injectable({
  providedIn: 'root',
})
export class VacanciesService {
  vacancyUpdateQueue = new PromiseQueue();

  constructor(
    private i18nPipe: I18NextPipe,
    private languageService: LanguageService,
    private graphQLService: GraphQLService,
    private store: Store,
    private vacanciesSubscribeGQL: VacanciesSubscribeGQL,
    private vacancyIdsSubscribeGQL: VacancyIdsSubscribeGQL,
    private vacancyCreateGQL: VacancyCreateGQL,
    private vacancyUpdateGQL: VacancyUpdateGQL,
    private vacanciesCountGQL: VacanciesCountGQL,
    private vacancySubscribeGQL: VacancySubscribeGQL,
    private vacancyListItemSubscribeGQL: VacancyListItemSubscribeGQL,
    private vacancyQueryGQL: VacancyQueryGQL,
    private vacancyHistoryListGQL: VacancyHistoryListGQL,
    private vacancyUpdateManyGQL: VacancyUpdateManyGQL,
    private globalSheetsService: GlobalSheetsService,
    private userService: UserService,
    private customerBranchService: CustomerBranchService,
    private router: Router,
  ) {}

  subscribeVacancies({
    limit,
    sort,
    filter,
  }: Omit<VacanciesSubscribeSubscriptionVariables, 'skip'>) {
    return this.vacanciesSubscribeGQL
      .subscribe(
        {
          skip: 0,
          limit,
          sort,
          filter,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => ({
          items: res.data?.vacanciesSubscribe?.items || [],
          itemIds: res.data?.vacanciesSubscribe?.itemIds || [],
          changedItems: res.data?.vacanciesSubscribe?.changedItems || [],
          removedItems: res.data?.vacanciesSubscribe?.removedItems || [],
          addedItems: res.data?.vacanciesSubscribe?.addedItems || [],
          totalCount: res.data?.vacanciesSubscribe?.totalCount || null,
        })),
        scan((acc, result) => {
          return applySubscriptionChangesToItems(acc, result, '_id');
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          throw err;
        }),
      );
  }

  subscribeVacancyIds({
    limit,
    sort,
    filter,
  }: Omit<VacancyIdsSubscribeSubscriptionVariables, 'skip'>) {
    return this.vacancyIdsSubscribeGQL
      .subscribe(
        {
          skip: 0,
          limit,
          sort,
          filter,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => res.data?.vacancyIdsSubscribe),
        catchError((err) => {
          this.graphQLService.handleError(err);
          throw err;
        }),
      );
  }

  getVacanciesCount(variables: VacanciesCountQueryVariables) {
    return this.vacanciesCountGQL
      .fetch(variables, { fetchPolicy: 'no-cache' })
      .pipe(
        map((result) => {
          return result.data.vacanciesQuery.totalCount;
        }),
      );
  }

  getVacancy$(uuid: string) {
    return this.vacancyQueryGQL
      .fetch({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data[`vacancyQuery`];
        }),
        filter(
          (vacancy): vacancy is Vacancy & { uniBaseX: UniBaseXVacancy } =>
            !!vacancy.uniBaseX,
        ),
        shareReplay(1),
      );
  }

  subscribeVacancy$(uuid: string) {
    return this.vacancySubscribeGQL
      .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data?.vacancySubscribe;
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          return [];
        }),
      );
  }

  subscribeVacancyListItem$(uuid: string) {
    return this.vacancyListItemSubscribeGQL
      .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data?.vacancySubscribe;
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          return [];
        }),
      );
  }

  createVacancy$(input: VacancyCreateMutationVariables['input']) {
    return this.vacancyCreateGQL.mutate({ input }).pipe(
      map((res) => {
        if (!res.data?.vacancyCreate) {
          throw new Error('Server failed to create new vacancy!');
        }
        return res.data?.vacancyCreate;
      }),
    );
  }

  queueVacancyUpdate(input: VacancyUpdateMutationVariables['input']) {
    return fromPromise(
      this.vacancyUpdateQueue.addToQueue(async () =>
        firstValueFrom(this.updateVacancy$(input)),
      ),
    );
  }

  updateVacancy$(input: VacancyUpdateMutationVariables['input']) {
    return this.vacancyUpdateGQL.mutate(
      { input },
      {
        context: {
          useMultipart: true,
        },
      },
    );
  }

  updateVacancies(input: VacancyUpdateManyMutationVariables['input']) {
    return this.vacancyUpdateManyGQL.mutate({ input }).pipe(
      map((res) => {
        return res.data?.vacancyUpdateMany;
      }),
    );
  }

  getProficiencyName(value: LanguageProficiencyEnum) {
    return getLanguageRequirementsTextOptions(this.i18nPipe).find(
      (option) => option.value === value,
    )?.label;
  }

  getVacancyActionOptions(
    vacancy: Pick<
      VacancyListItemFragment,
      'uniBaseX' | 'permissions' | 'uuid' | 'status'
    >,
    user?: UserSelfFragment,
  ): StructuredVacancyAllowedActions {
    const primaryActions: VacancyAllowedAction[] = [];
    const secondaryActions: VacancyAllowedAction[] = [];
    const sheetActions: VacancyAllowedAction[] = [];
    const canTransition = vacancy.permissions?.canTransition || [];
    const hasUnpublishedChanges =
      vacancy.uniBaseX?.unpublishedChanges &&
      vacancy.uniBaseX?.unpublishedChanges?.length > 0;

    const duplicateAction: VacancyAllowedAction = {
      label: 'duplicateVacancy',
      shortLabel: 'duplicate',
      icon: 'copy_plus_duplicate',
      action: () => {},
      inVacancyDetail: true,
      theme: 'secondary',
    };

    // Primary Actions

    // 1. Publish vacancy
    if (canTransition.includes(VacancyStatusTransitionEnum.PUBLISH)) {
      primaryActions.push({
        label: 'publishVacancy',
        icon: 'upload_cloud',
        action: () => {},
        inVacancyDetail: true,
        theme: 'secondary',
      });
    }

    // 2. Publish changes
    if (
      canTransition.includes(
        VacancyStatusTransitionEnum.CORRECT_OR_REPUBLISH,
      ) &&
      hasUnpublishedChanges
    ) {
      primaryActions.push({
        label: 'publishChanges',
        icon: 'upload_cloud',
        action: () => {},
        inVacancyDetail: true,
        theme: 'secondary',
      });
    }

    // 3. Remove scheduling
    if (canTransition.includes(VacancyStatusTransitionEnum.UNSCHEDULE)) {
      primaryActions.push({
        label: 'unschedule',

        icon: 'lock_open_unlock',
        action: () => {},
        inVacancyDetail: true,
        theme: 'secondary',
      });
    }

    // 4. Restore
    if (canTransition.includes(VacancyStatusTransitionEnum.RESTORE)) {
      primaryActions.push(duplicateAction);
      secondaryActions.push({
        label: 'restore',
        icon: 'restore_unarchive',
        action: () => {},
        inVacancyDetail: true,
        theme: 'secondary',
      });
    }
    if (
      user &&
      userHasMatchingEnabled(user) &&
      vacancy.status === VacancyStatusEnum.LIVE
    ) {
      secondaryActions.push({
        label: 'matching',
        icon: 'user_checkmark',
        action: () => {
          // navigate to hrflow-iframe/:vacancyUuid/
          // this.navigationService
          this.router.navigate(['hrflow-iframe', vacancy.uuid]);
        },
        inVacancyDetail: false,
        theme: 'secondary',
      });
    }

    // 5. Finish
    if (canTransition.includes(VacancyStatusTransitionEnum.ARCHIVE)) {
      primaryActions.push({
        label: 'endVacancy',
        shortLabel: 'stop',
        icon: 'archive',
        action: () => {},
        inVacancyDetail: true,
        theme: 'secondary',
      });
    }

    // 6. Delete
    if (canTransition.includes(VacancyStatusTransitionEnum.DELETE)) {
      primaryActions.push({
        label: 'delete',
        icon: 'trash',
        action: () => {},
        inVacancyDetail: true,
        theme: 'secondary',
      });
    }

    // Secondary Actions
    // a. Duplicate
    if (!canTransition.includes(VacancyStatusTransitionEnum.RESTORE)) {
      secondaryActions.push(duplicateAction);
    }

    // b. Preview
    const previewAction: VacancyAllowedAction = {
      label: 'preview',
      icon: 'visability_eye',
      action: () => {},
      inVacancyDetail: true,
      theme: 'secondary',
    };
    sheetActions.push(previewAction);
    secondaryActions.push(previewAction);

    // c. Changes
    secondaryActions.push({
      label: 'history',
      icon: 'clock_time_version_history',
      action: () => {
        this.globalSheetsService.openGlobalSheet({
          type: GlobalSheetTypeEnum.VACANCIES_CHANGELOG,
          uuid: vacancy.uuid,
        });
      },
      inVacancyDetail: false,
      theme: 'secondary',
    });

    return {
      primaryActions,
      secondaryActions,
      sheetActions,
    };
  }

  public getVacancyUniversaljobUrl(vacancyNumber?: string): string {
    return new URL(
      `${environment.universaljobHost}/${this.languageService
        .getActiveLanguage()
        .toLowerCase()}/job-frame/${vacancyNumber || '?'}`,
    ).href;
  }

  public getVacancyPreviewUrl(uuid: string, secret: string) {
    return new URL(
      `${environment.universaljobHost}/` +
        this.languageService.getActiveLanguage().toLowerCase() +
        `/job-preview/${uuid}?token=${secret}`,
    ).href;
  }

  getVacancyHistory(uuid: string) {
    return this.vacancyHistoryListGQL
      .fetch({ vacancyUuid: uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data.vacancyHistoryList;
        }),
      );
  }

  public async getDefaultValues() {
    const user = this.userService.user;
    if (!user) {
      throw new Error('Missing user to create vacancy');
    }
    let customerBranchUuid = user.organisationUnit?.branches?.[0]?.uuid;
    if (!customerBranchUuid && user.permissions?.canEditVacancy?.fullAccess) {
      customerBranchUuid = await firstValueFrom(
        this.customerBranchService.customerBranches$,
      ).then((branches) => {
        return branches[0]?.uuid;
      });
    }

    if (!customerBranchUuid) {
      throw new Error('Missing customerBranchUuid to create vacancy');
    }

    const input: Writable<UniBaseXVacancyCreateInput> = {
      responsibleUserId: user._id,
      customerBranchUuid: customerBranchUuid,
    };
    input.publishExactJobLocation = false;
    input.workloadFlexible = false;
    input.employmentType = VacancyEmploymentTypeEnum.PERMANENT;
    input.salaryFlexible = false;
    input.salaryCurrency = CurrencyEnum.CHF;
    input.salaryPaymentsPerYear = 12;
    input.salaryHoursPerWeek = 42;
    input.salaryUnit = SalaryUnitEnum.MONTH;
    input.publishSalary = false;
    input.languageRequirements = [
      {
        language: getActiveLanguageAsVacancyLanguage(this.languageService),
        proficiency: LanguageProficiencyEnum.C2,
        uuid: defaultLanguageEntryID,
      },
    ];
    return input;
  }
}
