import { NgModule } from '@angular/core';
import { ConsultantSelectComponent } from './consultant-select.component';
import { TemplateSingleSelectModule } from '../template-single-select/template-single-select.module';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { ContextMenuModule, TooltipModule } from '@intemp/unijob-ui';
import { ConsultantSelectSelectedItemCollapsedComponent } from './consultant-select-selected-item-collapsed/consultant-select-selected-item-collapsed.component';
import {
  SelectComponent,
  SelectionListComponent,
  TextboxListItemComponent,
} from '@intemp/unijob-ui2';
import { ConsultantSelectListItemComponent } from './consultant-select-list-item/consultant-select-list-item.component';
import { LetDirective } from '@ngrx/component';

@NgModule({
  declarations: [ConsultantSelectComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TemplateSingleSelectModule,
    SharedDefaultModule,
    UserAvatarModule,
    TooltipModule,
    ContextMenuModule,
    ConsultantSelectSelectedItemCollapsedComponent,
    SelectComponent,
    SelectionListComponent,
    ConsultantSelectListItemComponent,
    LetDirective,
    TextboxListItemComponent,
  ],
  exports: [ConsultantSelectComponent],
})
export class ConsultantSelectModule {}
