import { Component, Input } from '@angular/core';
import { UserAvatarModule } from '../../user-avatar/user-avatar.module';
import { ContextMenuModule, TooltipModule } from '@intemp/unijob-ui';
import { I18NextModule } from 'angular-i18next';
import { NgIf } from '@angular/common';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { filter } from 'rxjs/operators';
import { filterIsNotEmpty } from '../../../helpers/functions/filterIsNotEmpty';
import { UserService } from '../../../../models/shared/user/user.service';
import {
  ButtonTextComponent,
  ISelectedItem,
  SelectedItem,
} from '@intemp/unijob-ui2';
import { UserListItemFragment } from '../../../../graphql/generated';

@Component({
  selector: 'app-consultant-select-selected-item-collapsed',
  standalone: true,
  imports: [
    UserAvatarModule,
    I18NextModule,
    NgIf,
    SharedDefaultModule,
    TooltipModule,
    ContextMenuModule,
    ButtonTextComponent,
  ],
  templateUrl: './consultant-select-selected-item-collapsed.component.html',
  styleUrl: './consultant-select-selected-item-collapsed.component.scss',
})
export class ConsultantSelectSelectedItemCollapsedComponent
  implements ISelectedItem<string, UserListItemFragment>
{
  @Input()
  size = 'M';
  get consultant(): UserListItemFragment {
    return this.selectedItems[0].label;
  }

  @Input({ required: true })
  selectedItems!: [
    SelectedItem<string, UserListItemFragment>,
    ...SelectedItem<string, UserListItemFragment>[],
  ];

  loggedInUser$ = this.userService.user$.pipe(filter(filterIsNotEmpty));

  constructor(private userService: UserService) {}
}
