import { Option, ReadonlyArray } from 'effect';
import tailOrEmpty from './tailOrEmpty';

/**
 * In an array of elements set non-existing elements from a default array
 * (.e.g settings) at the exact position they are in the default array only
 * if these do not yet exist in the elements array.
 *
 * See test for use cases.
 *
 * @param defaultElements   Default elements, e.g. settings
 * @param elements          Saved settings which may contain all or some elements of default
 * @param isEquivalent      Comparator function returns true when to elements are equal
 *
 * @return                  The elements with the default elements in the correct position if they were missing
 */
export function setDefaultElementsInPosition<A, B>(
  defaultElements: ReadonlyArray<A>,
  elements: ReadonlyArray<B>,
  isEquivalent: (self: A | B, that: A | B) => boolean,
) {
  return setDefaultElementsInPosition_(
    ReadonlyArray.empty<A>(),
    defaultElements,
    elements,
    isEquivalent,
  );
}

function setDefaultElementsInPosition_<A, B>(
  results: ReadonlyArray<A | B>,
  defaultElements: ReadonlyArray<A>,
  elements: ReadonlyArray<B>,
  isEquivalent: (self: A | B, that: A | B) => boolean,
): ReadonlyArray<A | B> {
  return ReadonlyArray.head(defaultElements).pipe(
    // There is a default element
    Option.map((defaultElement) =>
      ReadonlyArray.some(elements, (element) =>
        isEquivalent(defaultElement, element),
      ) ||
      ReadonlyArray.some(results, (resultElement) =>
        isEquivalent(defaultElement, resultElement),
      )
        ? // There is a matching element in the elements array or already in the results
          {
            newDefaultElements: tailOrEmpty(defaultElements),
            newElements: tailOrEmpty(elements),
            maybeResult: ReadonlyArray.head(elements),
          }
        : // There is no matching element
          {
            newDefaultElements: tailOrEmpty(defaultElements),
            newElements: elements,
            maybeResult: Option.some<A>(defaultElement),
          },
    ),
    // Default elements was empty
    Option.getOrElse(() => ({
      newDefaultElements: ReadonlyArray.empty<A>(),
      newElements: tailOrEmpty(elements),
      maybeResult: ReadonlyArray.head(elements),
    })),
    ({
      newDefaultElements,
      newElements,
      maybeResult,
    }: {
      newDefaultElements: ReadonlyArray<A>;
      newElements: ReadonlyArray<B>;
      maybeResult: Option.Option<A | B>;
    }) =>
      Option.match(maybeResult, {
        onSome: (result: A | B) =>
          setDefaultElementsInPosition_(
            [...results, result],
            newDefaultElements,
            newElements,
            isEquivalent,
          ),
        onNone: () => results,
      }),
  );
}
