import { Injectable } from '@angular/core';
import { AnyUser } from './models/types';
import { storedDocumentToJsFile } from './shared/helpers/documents/storedDocumentToJsFile';

@Injectable({
  providedIn: 'root',
})
export class UserAvatarService {
  userAvatarObjectUrlMap: { [userId: string]: string } = {};

  constructor() {}

  getAvatarObjectUrl(user: AnyUser): string | undefined {
    if (!this.userAvatarObjectUrlMap[user._id] && user.profile.image) {
      this.userAvatarObjectUrlMap[user._id] = URL.createObjectURL(
        storedDocumentToJsFile(user.profile.image),
      );
    }
    return this.userAvatarObjectUrlMap[user._id];
  }
}
