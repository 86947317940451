import { LanguageProficiencyEnum } from '../../../../graphql/generated';
import { I18NextPipe } from 'angular-i18next';

export const getLanguageRequirementsTextOptions = (i18next: I18NextPipe) => {
  return [
    {
      value: LanguageProficiencyEnum.A1,
      label: i18next.transform('LanguageProficiencyEnum.A1'),
    },
    {
      value: LanguageProficiencyEnum.A2,
      label: i18next.transform('LanguageProficiencyEnum.A2'),
    },
    {
      value: LanguageProficiencyEnum.B1,
      label: i18next.transform('LanguageProficiencyEnum.B1'),
    },
    {
      value: LanguageProficiencyEnum.B2,
      label: i18next.transform('LanguageProficiencyEnum.B2'),
    },
    {
      value: LanguageProficiencyEnum.C1,
      label: i18next.transform('LanguageProficiencyEnum.C1'),
    },
    {
      value: LanguageProficiencyEnum.C2,
      label: i18next.transform('LanguageProficiencyEnum.C2'),
    },
    {
      value: LanguageProficiencyEnum.ADVANTAGEOUS,
      label: i18next.transform('LanguageProficiencyEnum.Advantageous'),
    },
  ] as const;
};
