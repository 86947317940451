import { on } from '@ngrx/store';
import { Tababble } from './state.types';
import { tabActions } from './actions';
import { Option, pipe } from 'effect';
import {
  addPermanentFilter,
  changeFilters,
  changeFilterTabName,
  removePermanentFilter,
  replacePermanentFilterCriteriaOfCurrentUser,
  setFilterPinnedState,
  setValidActiveTab,
  tableColumnVisibilityWithIdForTab,
  tableConfigurationForTabId,
  updateEnabledColumnWidthsForTabId,
} from '../advanced-data-list/advanced-data-list.optics';
import * as Optic from '@fp-ts/optic';
import { PAGE_SIZE } from '../vacancies-list/vacancies-list.reducer';
import { convertChipsToCriteria } from '../shared-helpers/convertChipsToCriteria';

export const tabReducers = <State extends Tababble>(
  actions: ReturnType<typeof tabActions<State, any>>,
) => [
  on(
    actions.editTabStarted,
    (_state: State): State => ({
      ..._state,
      editActiveTabId: true,
    }),
  ),
  on(
    actions.editTabEnded,
    (_state: State): State => ({
      ..._state,
      editActiveTabId: false,
    }),
  ),
  on(
    actions.tabDeletionConfirmed,
    (_state: State): State =>
      Option.match(_state.tabToDeleteId, {
        onSome: (tabId) =>
          pipe(
            removePermanentFilter<State>(tabId)(_state),
            setValidActiveTab<State>,
          ),
        onNone: () => _state,
      }),
  ),
  on(
    actions.tabDeleted,
    (_state: State, { tabId }): State =>
      pipe(
        removePermanentFilter<State>(tabId)(_state),
        setValidActiveTab<State>,
      ),
  ),
  on(
    actions.newTabAdded,
    actions.tabDuplicated,
    (_state: State, { tab }): State =>
      pipe(addPermanentFilter<State>(tab)(_state), (state) => ({
        ...state,
        activeTabId: tab.uuid,
        totalItemsCount: Option.none(),
      })),
  ),
  on(
    actions.tabsReordered,
    (_state: State, { tabs }): State => changeFilters<State>(tabs)(_state),
  ),

  on(
    actions.tabNameChanged,
    (_state: State, { tabId, newName }): State =>
      changeFilterTabName(_state, tabId, newName),
  ),
  on(
    actions.tabDeleteClicked,
    (_state: State, { tabId }): State => ({
      ..._state,
      ...{ tabToDeleteId: Option.some(tabId) },
      totalItemsCount: Option.none(),
    }),
  ),
  on(
    actions.permanentQueryChanged,
    (_state: State, { searchChips, tabId }): State => ({
      ...replacePermanentFilterCriteriaOfCurrentUser<State>(
        _state,
        tabId,
        convertChipsToCriteria(searchChips),
        // convertChipsToCriteria<State['tabs'][number]['criteria'][number]['condition']>(searchChips),
      ),
      displayItemsLimit: PAGE_SIZE,
    }),
  ),
  on(
    actions.newActiveTabSelected,
    (_state: State, { tabId }): State => ({
      ..._state,
      displayItemsLimit: PAGE_SIZE,
      editActiveTabId: false,
      totalItemsCount: Option.none(),
      activeTabId: tabId,
    }),
  ),
  on(
    actions.tabPinningChanged,
    (_state: State, { tabId, isPinned }): State =>
      pipe(setFilterPinnedState(_state, tabId, isPinned), setValidActiveTab),
  ),
  on(
    actions.enabledColumnWidthsUpdated,
    (_state: State, { newWidths, tabId }): State =>
      updateEnabledColumnWidthsForTabId<State>(tabId, newWidths)(_state),
  ),
  on(
    actions.columnVisibilityChanged,
    (_state: State, { columnId, tabId, visible }): State =>
      Optic.replace(tableColumnVisibilityWithIdForTab<State>(tabId, columnId))(
        visible,
      )(_state),
  ),
  on(
    actions.columnOrderChanged,
    (_state: State, { columns, tabId }): State =>
      Optic.replace(tableConfigurationForTabId<State>(tabId))(columns)(_state),
  ),
  on(
    actions.updateActiveTabCurrentCount,
    (_state: State, { tabId, totalItemsCount }): State => ({
      ..._state,
      ...{
        tabTotalCounts: {
          ..._state.tabTotalCounts,
          [tabId]: totalItemsCount,
        },
      },
    }),
  ),
];
